<div class="left-side-panel">
  <div class="align-panel" >
    <h5>{{curSite?.siteName}}</h5>
  </div>
  <ul>
      <li [ngClass]="{'active' : routeName == 'sm-dashboard'}"><a (click)="goToLink('sm-dashboard')">Dashboard</a></li>
      <li [ngClass]="{'active' : routeName == 'sm-general'}"><a (click)="goToLink('sm-general')">General</a></li>
      <li [ngClass]="{'active' : routeName == 'sm-products'}"><a (click)="goToLink('sm-products')">Products</a></li>
      <li [ngClass]="{'active' : routeName == 'sm-contract-settings'}"><a (click)="goToLink('sm-contract-settings')">Contract Settings</a></li>
      <li [ngClass]="{'active' : routeName == 'sm-triggers'}"><a (click)="goToLink('sm-triggers')">Triggers</a></li>
      <li [ngClass]="{'active' : routeName == 'sm-links'}"><a (click)="goToLink('sm-links')">Links</a></li>
      <li [ngClass]="{'active' : routeName == 'sm-marketing'}"><a (click)="goToLink('sm-marketing')">Marketing</a></li>
      <li [ngClass]="{'active' : routeName == 'sm-contracts'}"><a (click)="goToLink('sm-contracts')">Contracts</a></li>
      <li [ngClass]="{'active' : routeName == 'sm-discounts'}"><a (click)="goToLink('sm-discounts')">Discounts</a></li>
      <li [ngClass]="{'active' : routeName == 'sm-employees'}"><a (click)="goToLink('sm-employees')">Employees</a></li>
      <li [ngClass]="{'active' : routeName == 'sm-engage-dash'}"><a (click)="goToLink('sm-engage-dash')">SalesOptima Dash</a></li>
      <li [ngClass]="{'active' : routeName == 'sm-vessels'}"><a (click)="goToLink('sm-vessels')">Vessels</a></li>
  </ul>
</div>
