<div class="main-wrapper-section">
  <div class="common-header">
    <h3>Site Fields Names </h3>
  </div>
  <div class="card-section">
    <div class="card-panel">
      <form [formGroup]="SFNForm" (ngSubmit)="onSubmitClubConfigForm()">
        <div class="field-section">
          <div class="field-panel">
            <fieldset>
              <legend>Links</legend>
              <div class="form-group">
                <label>Custom 1 URL</label>
                <input class="form-control" formControlName="cust1Url" maxlength="50"/>
              </div>
              <div class="form-group">
                <label>Custom 2 URL</label>
                <input class="form-control" formControlName="cust2Url" maxlength="50"/>
              </div>
              <div class="form-group">
                <label>Custom 3 URL</label>
                <input class="form-control" formControlName="cust3Url" maxlength="50"/>
              </div>
              <div class="form-group">
                <label>Custom 4 URL</label>
                <input class="form-control" formControlName="cust4Url" maxlength="50"/>
              </div>
              <div class="form-group">
                <label>Custom 5 URL</label>
                <input class="form-control" formControlName="cust5Url" maxlength="50"/>
              </div>
            </fieldset>
          </div>

          <div class="field-panel">
            <fieldset>
              <legend>Marketing</legend>
              <div class="field-form-panel">
                <div class="field-form">
                  <div class="form-group">
                    <label>Custom Text 1</label>
                    <input class="form-control" formControlName="cust1Txt" maxlength="50"/>
                  </div>
                  <div class="form-group">
                    <label>Custom Text 2</label>
                    <input class="form-control" formControlName="cust2Txt" maxlength="50"/>
                  </div>
                  <div class="form-group">
                    <label>Custom Text 3</label>
                    <input class="form-control" formControlName="cust3Txt" maxlength="50"/>
                  </div>
                  <div class="form-group">
                    <label>Custom Text 4</label>
                    <input class="form-control" formControlName="cust4Txt" maxlength="50"/>
                  </div>
                  <div class="form-group">
                    <label>Custom Text 5</label>
                    <input class="form-control" formControlName="cust5Txt" maxlength="50"/>
                  </div>
                </div>
                <div class="field-form">
                  <div class="form-group">
                    <label>Multi Text 1</label>
                    <input class="form-control" formControlName="multiTxt1" maxlength="50"/>
                  </div>
                  <div class="form-group">
                    <label>Multi Text 2</label>
                    <input class="form-control" formControlName="multiTxt2" maxlength="50"/>
                  </div>
                  <div class="form-group">
                    <label>Multi Text 3</label>
                    <input class="form-control" formControlName="multiTxt3" maxlength="50"/>
                  </div>
                  <div class="form-group">
                    <label>Multi Text 4</label>
                    <input class="form-control" formControlName="multiTxt4" maxlength="50"/>
                  </div>
                  <div class="form-group">
                    <label>Multi Text 5</label>
                    <input class="form-control" formControlName="multiTxt5" maxlength="50"/>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>

          <div class="field-panel">
            <fieldset>
                <legend>SalesOptima Dash</legend>
              <div class="field-form-panel">
                <div class="field-form">
                  <div class="form-group">
                    <label>Custom MMT ID1</label>
                    <input class="form-control" formControlName="customMMT1" maxlength="50"/>
                  </div>
                  <div class="form-group">
                    <label>Custom MMT ID2</label>
                    <input class="form-control" formControlName="customMMT2" maxlength="50"/>
                  </div>
                  <div class="form-group">
                    <label>Custom MMT ID3</label>
                    <input class="form-control" formControlName="customMMT3" maxlength="50"/>
                  </div>
                  <div class="form-group">
                    <label>Custom MMT ID4</label>
                    <input class="form-control" formControlName="customMMT4" maxlength="50"/>
                  </div>
                  <div class="form-group">
                    <label>Custom MMT ID5</label>
                    <input class="form-control" formControlName="customMMT5" maxlength="50"/>
                  </div>
                </div>
                <div class="field-form">
                  <div class="form-group">
                    <label>Custom MMT ID6</label>
                    <input class="form-control" formControlName="customMMT6" maxlength="50"/>
                  </div>
                  <div class="form-group">
                    <label>Custom MMT ID7</label>
                    <input class="form-control" formControlName="customMMT7" maxlength="50"/>
                  </div>
                  <div class="form-group">
                    <label>Custom MMT ID8</label>
                    <input class="form-control" formControlName="customMMT8" maxlength="50"/>
                  </div>
                  <div class="form-group">
                    <label>Custom MMT ID9</label>
                    <input class="form-control" formControlName="customMMT9" maxlength="50"/>
                  </div>
                  <div class="form-group">
                    <label>Custom MMT ID10</label>
                    <input class="form-control" formControlName="customMMT10" maxlength="50"/>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>

        <div class="button-group">
          <div class="form-group">
            <button class="primary-btn" >Save<i *ngIf="submitted" class="fas fa-spinner fa-spin"></i></button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<!--<div class="loader-body" *ngIf="showSpinner">
  <div class="lds-ripple"><div></div><div></div></div>
</div>-->
