import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, ViewChild, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BCMCLPUser, TeamCodes } from '../../models/clpuser.model';
import { CMClub, CMClubResponse, CMSite, CMSiteEmployees, CMSiteEmployeesAccept, CMSiteEmployeesListResponse, SiteDDListResponse } from '../../models/cm-club-service.model';
import { DropDownItem, IntDropDownItem, SimpleResponse } from '../../models/generic-response.model';
import { Fee, Product, ProductResponse, SimpleProduct } from '../../models/product.model';
import { GlobalService } from '../../services/shared/global.service';
import { LocalService } from '../../services/shared/local.service';
import { UtilityService } from '../../services/shared/utility.service';
import * as $ from "jquery";
import { AutoCompleteComponent, DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CMContractService } from '../../services/cm-contract.service';
import { CMClubService } from '../../services/cm-club.service';
import { CMContractMember, Contact, ContactDetails, CMContract, ContractResponse, CMContractSetting, CMContractSettingResponse, CMMemberStatus, CMContractFeeListResponse, CMContractFee, CMMemberFeeListResponse, CMMemberFee, FeeButton, ContractPdfConvertContainer, CMContractLog, eREF_CMContractStatus, eREF_CMMemberStatus } from '../../models/cm-contract';
import { ProductService } from '../../services/product.service';
import { FormatSettings } from '@progress/kendo-angular-dateinputs';
import { NotificationService } from '../../services/notification.service';
import { CountryStateService } from '../../services/country-state.service';
import { Search, SearchListResponse } from '../../models/search.model';
import { SearchContactService } from '../../services/shared/search-contact.service';
import { isNullOrUndefined } from 'util';
import { DatePipe } from '@angular/common';
import { CountryPlaceholder } from '../../models/country-state.model';
import { CountryCode } from 'libphonenumber-js';
import { Title } from '@angular/platform-browser';
import { PaymentService } from '../../services/payment.service';
import { AppConfigService } from '../../services/shared/app-config.service';
import { ConfigDetails } from '../../models/app-config.model';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { SignaturePad } from 'angular2-signaturepad';
import { filter } from '@progress/kendo-angular-editor/util';
declare var $: any;

@Component({
    selector: 'app-cm-contract',
    templateUrl: './cm-contract.component.html',
    styleUrl: './cm-contract.component.css',
    providers: [provideNativeDateAdapter()],

})
export class CmContractComponent implements AfterViewInit {
    agentForm: FormGroup;
    siteTaxForm: FormGroup;
    termForm: FormGroup;
    paymentForm: FormGroup;
    contactForm: FormGroup;
    contactForm2: FormGroup;
    textForm: FormGroup;
    testModeForm: FormGroup;

    @ViewChild('autocomplete') autocomplete: AutoCompleteComponent;
    @ViewChild('sigpadFinal', { static: true }) signaturePadFinal: SignaturePad;
    isAI: boolean;
    contactId: number = 0;
    fromContactId: number = 0;
    encryptedUser: string = ''
    user: BCMCLPUser;
    mailMergeTemplateList: IntDropDownItem[];
    cMProductUnused: SimpleProduct[];
    curSite: CMSite = <CMSite>{}
    curTeam: TeamCodes = <TeamCodes>{};
    curSiteSub: Subscription;
    clubConfig: CMClub;
    productList: Product[];
    all_productList: Product[];
    product: Product = <Product>{}
    /*    countryDD: DropDownItem[];*/
    stateList: DropDownItem[];
    countryDD: CountryPlaceholder[];
    clubId: number = 0;
    isContractReview: boolean = false;
    isReview: boolean = false;
    filterSettings: DropDownFilterSettings = {
        caseSensitive: false,
        operator: 'contains',
    };
    placeHolder: string = "";
    mobile_mask: string = '(000) 000-0000';
    selectedAgreements: number[] = [];
    selectedRules: number[] = [];
    siteList: CMSite[];
    site: CMSite = <CMSite>{};
    clpCompanyId: number;
    contracts: CMContract[] = [];
    contract: CMContract = <CMContract>{};
    contractMember: CMContractMember = <CMContractMember>{};
    //contact: ContactDetails = <ContactDetails>{};;
    contacts: ContactDetails[];
    siteId: number = -1;
    classCode1: number = 0;
    public siteSelected: number;
    public siteSelectedName: string;
    public productSelected: number;
    public productSelectedName: string;
    memberFee: number = 0;
    memberFeeTax: any = 0;
    signupFee: number = 0;
    signupFeeTax: any = 0;
    totalSignupFee: number = 0;
    totalMemberFee: number = 0;
    sites: CMSite[];
    contactDetails: any = [];
    public format: FormatSettings = {
        displayFormat: 'MM/dd/yyyy',
        inputFormat: 'MM/dd/yy'
    };
    public signataurePadOptions = {
        'minWidth': 1,
        'maxWidth': 2,
        'minHeight': 150,
        'maxHeight': 200,
        penColor: 'black',
        backgroundColor: '#fff',
    };
    dtDate: Date = new Date();
    dtStartDate: Date = new Date();
    dtBeginDate: Date = new Date();
    dtEndDate: Date = new Date();
    dtDOB: Date = new Date();

    isContactCreate: boolean = false;
    countryCode: string = "US";
    stateCode: string = "";
    searchList: Search[] = [];
    inputBoxValue: string = "";
    contractId: number = 0;
    contactData: Contact = <Contact>{};
    contractEdit: boolean = false;
    dtSign: any;
    //dtCNStart: Date = new Date();
    //dtPayBegin: Date = new Date();
    //dtCntExp: Date = new Date();
    memberFeeUpdated: number;
    signupFeeUpdated: number;
    isSeasonal: boolean = false;
    isDays: boolean = false;
    isEveryday: boolean = false;

    ContractSetting: CMContractSetting = <CMContractSetting>{};
    placeholder: string = "";
    memContactId: number;
    delContactId: number;
    membersForm: FormGroup
    emailTxtMsg: boolean = false;
    contractFees: CMContractFee[];
    memberFeeList: CMMemberFee[];
    feeButtonList: FeeButton[] = [];
    siteEmployeesList: CMSiteEmployees[];
    isValidFee: boolean = true;
    isValidFinalFee: boolean = true;
    isMemberExist: boolean;
    taxRate: number;
    isEditMem: boolean = false
    maxMembers: number = 0
    dltMemIndex: number = 0;
    isPaymentDone: number = 0;
    showSpinner: boolean;

    txtContactId: number = 0;
    txtContact_i: number = 0;
    bcmSiteUrl: string;

    phSendContractMessageText: string = '';
    phSendContractMediaURL: string = '';
    contractStatusId: number = 0;
    isDownloaded: boolean;
    isDownloadedDL: boolean;
    isSignVisible: boolean = false;
    isSubscribe: any;
    isSubscribeDL: any;
    contractPdfConvertContainer: ContractPdfConvertContainer;
    contractPdfConvertContainerDL: ContractPdfConvertContainer;

    mySoLink: string = '';
    signOfficerEmpId: number = 0;
    isContractManager: boolean = false;
    cmContactId: number;
    primaryContactName: string = '';
    readonly panelOpenState = signal(false);
    disableSaveContract: boolean = false;
    isShowReview: boolean = false;
    contractAuditorList: CMSiteEmployees[];

    depositSummary: string = '';

    constructor(private localService: LocalService,
        private fb: FormBuilder,
        private _router: Router,
        private _route: ActivatedRoute,
        private _utilityService: UtilityService,
        private _globalService: GlobalService,
        private _contractService: CMContractService,
        private _clubService: CMClubService,
        private titleService: Title,
        private _productService: ProductService,
        private _notifyService: NotificationService,
        private _countryStateService: CountryStateService,
        private _searchContactService: SearchContactService,
        private _paymentService: PaymentService,
        private _appconfigService: AppConfigService,
        public datepipe: DatePipe,
    ) {
        this.titleService.setTitle('Contract Manager');
        this.localService.setShowMenu(true);
        this.localService.setIsContract(true);
        this.localService.setIsCmManager(false);
        this.localService.setRouteName("cm-contract")
    }

    ngOnInit() {
        this.agentForm = this.prepareAgentForm();
        this.termForm = this.prepareTermForm();
        this.siteTaxForm = this.prepareSiteTaxForm();
        this.paymentForm = this.preparePaymentForm();
        this.contactForm = this.prepareContactForm();
        this.contactForm2 = this.prepareContactForm2();
        this.membersForm = this.prepareMemberForm();
        this.textForm = this.prepareTextForm();
        this.testModeForm = this.prepareTestModeForm();


        this._route.queryParamMap.subscribe(params => {
            this.encryptedUser = params.get('r');
            if (params.get('isAI')) {
                this.isAI = params.get('isAI') == 'true' ? true : false
                localStorage.setItem("isAI", 'true')
            }
            else {
                this.isAI = localStorage.getItem("isAI") && localStorage.getItem("isAI") == 'true' ? true : false;
            }

            this._route.paramMap.subscribe(queryParams => {
                this.contactId = +queryParams.get('contactId');
                this.fromContactId = this.contactId;
            });

            if (this.encryptedUser && !this.encryptedUser?.includes(":")) {
                this.encryptedUser = this.encryptedUser + ":" + (this.isAI ? "1" : "0")
            }
            if (this.encryptedUser) {
                this.authenticateR().then(() => {
                    if (this.user) {
                        localStorage.setItem("token", this.encryptedUser);
                        this.refreshContract();
                        if (this.isAI) {
                            this._appconfigService.mySO_Site_URL()
                                .subscribe(async (result: ConfigDetails) => {
                                    if (result) {
                                        this.mySoLink = result?.configValue
                                    }
                                });
                        }
                        else {
                            this._appconfigService.bCMGetConfigKeyValue(this.encryptedUser, "MySo_Link")
                                .subscribe(async (result: ConfigDetails) => {
                                    if (result) {
                                        this.mySoLink = result?.configValue
                                    }
                                });
                        }
                    }
                    else {
                        this._router.navigate(['/unauthorized']);
                    }
                });
            }
            else {
                if (this.isAI) {
                    this._appconfigService.mySO_Site_URL()
                        .subscribe(async (result: ConfigDetails) => {
                            if (result) {
                                this.mySoLink = result?.configValue
                                window.location.href = this.mySoLink + `?cid=${this.contactId}&cm=1`
                            }
                        });
                }
                else {
                    this._router.navigate(['/unauthorized']);
                }
            }
        });
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.resizeCanvas();
        }, 2000)
    }

    async saveSignature() {
       
        let esignCapture: string = '';
        if (this.isSignVisible && this.signaturePadFinal?.isEmpty() == false) {
            esignCapture = this.signaturePadFinal.toDataURL('image/png', 0.9);
        }
        else if (this.isSignVisible && this.signaturePadFinal?.isEmpty() == true) {
            this._notifyService.showError("Please sign the document.", "", 7000);
            return;
        }
        console.log(this.siteEmployeesList, "Site Employee");
        console.log(this.user?.cLPUserID, "User Employee");
        if (this.siteEmployeesList.some(s => s.roleName.toLowerCase() == 'signing officer' && s.userID == this.user?.cLPUserID) == true) {
            console.log(this.siteEmployeesList, "inside Site Employee");
            console.log(this.user?.cLPUserID, "inside User Employee");
            let empId = this.siteEmployeesList.filter(s => s.userID == this.user?.cLPUserID && s.roleName.toLowerCase() == 'signing officer' && (s.siteID == this.siteId || s.siteID == 0))[0]?.iD;
            if (empId == null && empId == undefined) {
                empId = this.siteEmployeesList.filter(s => s.roleName.toLowerCase() == 'signing officer' && s.userID == this.user?.cLPUserID)[0]?.iD;
            }
            let empAccept = {} as CMSiteEmployeesAccept;
            empAccept.cmEmployeeID = empId;
            empAccept.cLPUserId = this.user.cLPUserID;
            empAccept.contactId = this.contactId;
            empAccept.sCLPUserId = this.user?.slurpyUserId > 0 ? this.user?.slurpyUserId : 0;
            empAccept.cmContractID = this.contractId;
            empAccept.isFaceToFace = -1;
            empAccept.eSigFullImage = esignCapture;
            empAccept.eSigLength = 0;
            empAccept.cLPCompanyID = this.user?.cLPCompanyID;
            this.showSpinner = true;
            await this._contractService.CMSiteEmployees_SignAccepted(this.encryptedUser, empAccept, this.isAI)
                .then(async (result: SimpleResponse) => {
                    if (result?.messageBool) {
                        this.Log(`Signing Officer (${this.user.firstName} ${this.user.lastName}) signature saved successfuly.`);

                        this.refreshContract();
                        this._notifyService.showSuccess("Signature saved successfuly.", "", 7000);
                        this.isReview = true;
                    }
                    this.showSpinner = false;
                })
                .catch((err: HttpErrorResponse) => {
                    this.showSpinner = false;
                    this._globalService.error("cm-contract.saveSignature", err.message, null);
                    this._utilityService.handleErrorResponse(err);
                });
        }
        else {
            this.isSignVisible = false;
        }

    }


    signatureFinalOnEnd() {
        if (this.signaturePadFinal?.isEmpty() == false) {
            //this.initialFinalDisabled = true;
        }
    }
    drawClearFinal(): boolean {
        this.signaturePadFinal.clear();
        this.signaturePadFinal.on();
        return false;
    }
    resizeCanvas() {
        var canvas = document.getElementsByTagName("canvas")[0];
        if (canvas) {
            var outer = document.getElementsByClassName("signature-pad");
            canvas.width = outer[0].clientWidth;
            canvas.height = 200;
        }
    }

    async dtSignedMembers_Update() {
        await this._contractService.dtSignedMembers_Update(this.encryptedUser, this.contractId, this.isAI)
            .then(async (result: SimpleResponse) => {
                if (result) {

                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-contract.dtSignedMembers_Update", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }


    async refreshContract(isPrimary = false) {
        this.showSpinner = true
        await this.dtSignedMembers_Update()
        this.getBCMBaseUrl()
        this.getCountry();
        this.getCountryStateList(this.countryCode);
        this.getInitialContact(isPrimary);
        this.agentForm.patchValue({
            agentName: this.user.firstName + " " + this.user.lastName
        });
        this.termForm.patchValue({
            term: ''
        });

        setTimeout(() => {
            this.showSpinner = false
        }, 3000)
    }

    //#region prepare forms

    prepareMemberForm() {
        return this.fb.group({
            members: this.fb.array([this.addMember()]),
        })
    }

    addMember(): FormGroup {
        return this.fb.group({
            firstName: [''],
            lastName: [''],
            add1: [''],
            city: [''],
            state: [''],
            zip: [''],
            email: [''],
            mobile: [''],
            signupFee: [''],
            membershipFee: [''],
            memberFees: this.fb.array([this.addMemberFees()]),
            contactId: [''],
            statusId: [0],
            cMMemberID: [0],
            isPrimary: [false],
            isF2FLinkCopied: [false],
            isExtraMem: [false],
            dtDL: new Date()
        });
    }

    addMemberFees() {
        return this.fb.group({
            cMContractFeeID: new FormControl(0),
            cmProductFeeID: new FormControl(0),
            cmProductID: new FormControl(0),
            cmMemberFeeID: new FormControl(0),
            cMFeeName: new FormControl(""),
            feeTotal: new FormControl(0),
            feeBase: new FormControl(0),
            feeTax: new FormControl(0),
            isTaxable: new FormControl(0)
        })
    }

    prepareAgentForm() {
        return this.fb.group({
            agentName: ['']
        })
    }

    prepareTestModeForm() {
        return this.fb.group({
            processorTestMode: [false]
        })
    }

    prepareTermForm() {
        return this.fb.group({
            term: ['']
        })
    }

    getBCMBaseUrl() {
        this._appconfigService.bCMGetConfigKeyValue(this.encryptedUser, "bcmsite")
            .subscribe(async (result: ConfigDetails) => {
                if (result) {
                    this.bcmSiteUrl = result?.configValue
                }
            });
    }

    prepareSiteTaxForm() {
        let expires = this.addDays(this.dtStartDate, 7);
        let end = this.addMonths(this.dtBeginDate, 12);

        return this.fb.group({
            SignupFee: ['', [Validators.required]],
            SignupFeeTax: [''],
            MembershipFee: ['', [Validators.required]],
            MembershipFeeTax: [''],
            fees: this.fb.array([]),
            dtSignedMembers: [''],
            contractStart: [this.dtStartDate],
            contractStartLabel: [this.getFormattedDate(this.dtStartDate)],
            contractEnd: [end],
            contractEndLabel: [this.getFormattedDate(end)],
            paymentBegin: [this.dtBeginDate],
            paymentBeginLabel: [this.getFormattedDate(this.dtBeginDate)],
            contractExpires: [expires],
            contractExpiresLabel: [this.getFormattedDate(expires)],
            specialterms: [''],
            productDesc: [''],
            isJanuary: [false],
            isFebruary: [false],
            isMarch: [false],
            isApril: [false],
            isMay: [false],
            isJune: [false],
            isJuly: [false],
            isAugust: [false],
            isSeptember: [false],
            isOctober: [false],
            isNovember: [false],
            isDecember: [false],
            isMonday: [false],
            isTuesday: [false],
            isWednesday: [false],
            isThursday: [false],
            isFriday: [false],
            isSaturday: [false],
            isSunday: [false]
        })
    }

    preparePaymentForm() {
        return this.fb.group({
            isEntryACH: [false],
            isEntryCC: [false],
            isEntryCheck: [false],
            isEntryCash: [false],
            isMonthlyACH: [false],
            isMonthlyCC: [false],
            isMonthlyCheck: [false],
            isAffirmEntry: [false]
        })
    }

    prepareContactForm() {
        return this.fb.group({
            txtFirstName: [{ value: '' }, [Validators.required]],
            txtLastName: [{ value: '' }, [Validators.required]],
            txtEmail: [{ value: '' }, [Validators.required, Validators.pattern(/^([a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$)/)]],
            txtMobilePhone: ['', [Validators.required]],
            txtHomePhone: [{ value: '' }],
            txtAddress: [{ value: '' }],
            txtCity: [{ value: '' }],
            txtZip: [{ value: '' }, [Validators.minLength(5), Validators.maxLength(9)]],
            contactId: [{ value: 0 }],
            country: ['US'],
            state: ['']
        })
    }

    prepareContactForm2() {
        return this.fb.group({
            dl: [{ value: '' }],
            dob: [''],
            emgContactName: [{ value: '' }],
            txtEmgCntPhone: [{ value: '' }],
        })
    }

    validateNumber(event: KeyboardEvent) {
        const charCode = event.which ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            event.preventDefault();
        }
    }

    validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched();
                control.updateValueAndValidity();

                if (!control.valid && !control.disabled) {
                    this._notifyService.showError(field, control.value + ' is invalid.', 5000);
                }
            } else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }

    private async authenticateR() {
        await this.localService.authenticateUser(this.encryptedUser, this.isAI)
            .then(async (result: BCMCLPUser) => {
                if (result) {
                    this.user = UtilityService.clone(result);
                    this.clpCompanyId = this.user?.cLPCompanyID;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("navMenu.authenticateR", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }

    //#endregion

    //#region dropdown activity

    async getCountry() {
        await this._countryStateService.getListWithPlaceholder(this.encryptedUser, this.isAI)
            .then(async (result: CountryPlaceholder[]) => {
                if (result) {
                    this.countryDD = UtilityService.clone(result);
                    this.countryDD?.unshift({
                        country_Code: "",
                        country_Name: "--Select--",
                        placeholder: "",
                        country_Code2: ""
                    });
                    this.onChangeCountry(this.countryCode)
                }

            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    onChangeCountry(countryCode) {
        this.stateCode = ""
        this.getCountryStateList(countryCode)
        var country_Code = this.countryDD.filter(x => x.country_Code == countryCode)[0]?.country_Code;
        this.placeholder = this.countryDD.filter(x => x.country_Code == countryCode)[0]?.placeholder;

        if (!isNullOrUndefined(country_Code) && !isNullOrUndefined(this.placeholder)) {
            this.placeHolder = this._countryStateService.parseSimplePhone(this.placeholder, country_Code as CountryCode);
            this.mobile_mask = this._countryStateService.replaceZero(this.placeHolder);
        }
    }

    async getCountryStateList(countryCode: string) {
        await this._countryStateService.getCountryStateList(this.encryptedUser, countryCode ? countryCode : "US")
            .then(async (result: DropDownItem[]) => {
                if (result) {
                    this.stateList = UtilityService.clone(result);
                    this.stateList?.unshift({
                        value: "",
                        text: "--Select--",
                        isSelected: false
                    });
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    //#endregion

    //#region contact

    async getContactDetails(callback, isPrimary) {
        await this._contractService.LoadContractMemberDisplay(this.encryptedUser, isPrimary ? this.fromContactId : this.contactId, this.isAI).
            then(async (result: any) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    if (response) {
                        if (callback) {
                            callback(response);
                        }
                    }
                }
                else {
                    this._notifyService.showError("This contact is not assigned with an active site.", "", 3000);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-contract.getContactDetails", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getInitialContact(isPrimary) {
        this.getContactDetails((response) => {
            this.contactDetails = [];
            this.depositSummary = response.depositSummary;
            this.contactDetails.push(response);
            this.classCode1 = response.class1Codes;
            this.primaryContactName = `${response.firstName} ${response.lastName}`;
            this.titleService.setTitle(this.primaryContactName);
            this.getSiteData();
        }, isPrimary);
    }

    async getClubDetails() {
        await this._clubService.clubGetByID(this.encryptedUser, this.clubId, this.isAI).
            then(async (result: CMClubResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this.isContractReview = response.clubConfig?.isContractReview;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-contract.getClubDetails", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getContactData(isRefresh: boolean = false) {
        this.getContactDetails((response) => {
            this.contactDetails = [];
            this.contactDetails.push(response);
            this.classCode1 = response.class1Codes;
            this.buildContactList(isRefresh);
        }, false);
    }

    async getContactById(_contactId: number) {
        this.getContactDetails((response) => {
            this.contactDetails.push(response);
            this.isContactCreate = true;
            this.patchContact(response);

            this.Log(`Contact (${response.firstName} ${response.lastName}: ${_contactId}) selected.`);
        }, false);
    }

    buildContactList(isRefresh: boolean = false) {
        if (this.contacts == null || this.contacts == undefined) {
            this.contacts = [];
        }
        this.contactDetails.forEach((c) => {
            let contact: ContactDetails = {
                contactId: c.contactID,
                add1: c.add1,
                city: c.city,
                country: c.country,
                state: c.state,
                zip: c.zip,
                mobile: c.mobile,
                homePhone: c.homePhone,
                firstName: c.firstName,
                lastName: c.lastName,
                email: c.email,
                signupFee: c.signupFee,
                memberFee: c.memberFee,
                custom4: c.custom4,
                custom5: c.custom5,
                custom6: c.custom6,
                custom7: c.custom7,
                dl: c.dl,
                dob: c.dob,
                emergencyCName: c.emergencyCName,
                emergencyCPhone: c.emergencyCPhone,
                cMMemberID: !isNullOrUndefined(this.contracts[0]?.members.filter(x => x.contactID == c.contactID)[0]?.cMMemberID) ? this.contracts[0]?.members.filter(x => x.contactID == c.contactID)[0]?.cMMemberID : 0,
                cMIsPrimary: !isNullOrUndefined(this.contracts[0]?.members.filter(x => x.contactID == c.contactID)[0]?.isPrimary) ? this.contracts[0]?.members.filter(x => x.contactID == c.contactID)[0]?.isPrimary : (this.fromContactId == c.contactID ? true : false),
                statusId: this.contracts[0]?.members.filter(x => x.contactID == c.contactID)[0]?.statusID,
                dtDL: this.contracts[0]?.members.filter(x => x.contactID == c.contactID)[0]?.dtDL
            };
            if (this.contacts.some(cnt => cnt.contactId == c.contactID) == false)
                this.contacts.push(contact);
            else {
                this.contacts.forEach((item) => {
                    if (item.contactId == c.contactID) {
                        item.add1 = contact.add1;
                        item.city = contact.city;
                        item.country = contact.country;
                        item.state = contact.state;
                        item.zip = contact.zip;
                        item.mobile = contact.mobile;
                        item.homePhone = contact.homePhone;
                        item.firstName = contact.firstName;
                        item.lastName = contact.lastName;
                        item.email = contact.email;
                        item.signupFee = contact.signupFee;
                        item.memberFee = contact.memberFee;
                        item.custom4 = contact.custom4;
                        item.custom5 = contact.custom5;
                        item.custom6 = contact.custom6;
                        item.custom7 = contact.custom7;
                        item.statusId = contact.statusId;
                        item.cMMemberID = contact.cMMemberID;
                        item.dl = contact.dl;
                        item.dob = contact.dob;
                        item.emergencyCName = contact.emergencyCName;
                        item.emergencyCPhone = contact.emergencyCPhone;
                        item.dtDL = contact.dtDL;
                    }
                });
            }
        });

        this.contacts = this.contacts.sort(({ cMIsPrimary: a = false }, { cMIsPrimary: b = false }) => Number(b) - Number(a));

        this.members.controls = [];
        this.members.removeAt(0);

        if (this.contacts?.length > 0) {
            //this.contacts = this.toSetPrimaryMemAtFirst(this.contacts);
            this.contacts?.forEach(async (element, index) => {

                let memFee: CMMemberFee[] = [];
                let member: CMContractMember = <CMContractMember>{}
                if (this.contracts[0]?.members?.some((item) => item.cMMemberID == element?.cMMemberID) == true) {
                    member = this.contracts[0]?.members?.filter((item) => item.cMMemberID == element?.cMMemberID)[0];
                    memFee = member?.CMMemberFees;
                }
                if (memFee && memFee?.length == 0) {
                    this.contracts[0]?.members[0]?.CMMemberFees.forEach((item) => {
                        let obj = {
                            cmContractFeeID: item?.cmContractFeeID,
                            cmProductFeeID: item?.cmProductFeeID,
                            cmProductID: item?.cmProductID,
                            cmMemberFeeID: 0,
                            cmFeeName: item?.cmFeeName,
                            feeTotal: item?.feeTotal,
                            feeTax: 0,
                            isTaxable: item?.isTaxable,
                            isRecurring: item?.isRecurring,
                            memberID: item?.memberID,
                            contactID: 0,
                            sOrder: item?.sOrder,
                            feeBase: 0,
                            isDone: false,
                            memberSplitable: 0,
                            isPrimary: false,
                            isPaymentSplitable: item?.isPaymentSplitable,
                            isCollectNow: item?.isCollectNow
                        }
                        memFee.push(obj)
                    });

                    //if no member fee exists, pull it from product 
                    if (memFee.length == 0) {
                        if (this.product?.fees?.length > 0) {
                            this.product?.fees.forEach((fee, index) => {
                                let obj = {
                                    cmContractFeeID: 0,
                                    cmProductFeeID: fee?.cmProductFeeID,
                                    cmProductID: fee?.cmProductID,
                                    cmMemberFeeID: 0,
                                    cmFeeName: fee?.cmFeeName,
                                    feeTotal: 0,
                                    feeTax: Math.round(this.changeToNumber(fee.feeAmount.toString()) * (this.site.taxRate / 100)),
                                    isTaxable: fee?.isTaxable,
                                    isRecurring: fee?.isRecurring,
                                    memberID: 0,
                                    contactID: 0,
                                    sOrder: fee?.sOrder,
                                    feeBase: this.fromContactId == element.contactId ? fee?.feeAmount : 0,
                                    isDone: false,
                                    memberSplitable: 0,
                                    isPrimary: false,
                                    isPaymentSplitable: fee?.isPaymentSplitable,
                                    isCollectNow: fee?.isCollectNow
                                }
                                memFee.push(obj)
                            });
                        }
                    }
                }

                memFee = memFee.sort((a, b) => a.sOrder - b.sOrder);
                let memObj = new FormGroup({
                    firstName: new FormControl(element.firstName),
                    lastName: new FormControl(element.lastName),
                    add1: new FormControl(element.add1),
                    city: new FormControl(element.city),
                    state: new FormControl(element.state),
                    zip: new FormControl(element.zip),
                    email: new FormControl(element.email),
                    mobile: new FormControl(element.mobile),
                    signupFee: new FormControl(this.changeToCurrency(element.signupFee)),
                    membershipFee: new FormControl(this.changeToCurrency(element.memberFee)),
                    memberFees: this.fb.array((memFee ?? []).map(fee => this.fb.group({
                        cMContractFeeID: new FormControl(fee?.cmContractFeeID),
                        cmProductFeeID: new FormControl(fee?.cmProductFeeID),
                        cmProductID: new FormControl(fee?.cmProductID),
                        cmMemberFeeID: new FormControl(fee?.cmMemberFeeID),
                        cMFeeName: new FormControl(fee?.cmFeeName),
                        feeTotal: new FormControl(this.changeToCurrency(fee.feeTotal)),
                        feeBase: new FormControl(this.changeToCurrency(fee.feeBase)),
                        feeTax: new FormControl(Math.round(this.changeToNumber(fee.feeTotal.toString()) * (this.site.taxRate / 100))),
                        isPrimary: new FormControl(element.cMIsPrimary),
                        isTaxable: new FormControl(fee?.isTaxable)
                    }))),
                    contactId: new FormControl(element.contactId),
                    statusId: new FormControl(element.statusId),
                    cMMemberID: new FormControl(element.cMMemberID),
                    isF2FLinkCopied: new FormControl(false),
                    isExtraMem: new FormControl(member?.isExtraMem),
                    isPrimary: new FormControl(element.cMIsPrimary),
                    dtDL: new FormControl(element.dtDL)
                })

                if (member.isPrimary) {
                    this.primaryContactName = `${element.firstName} ${element.lastName}`;
                    this.titleService.setTitle(this.primaryContactName);
                }

                this.members.push(memObj);
            });
        }

        if (this.contractFees?.length > 0) {
            this.fees.controls = [];
            this.fees.removeAt(0);
            this.feeButtonList = [];

            this.contractFees.forEach((fee, index) => {
                this.fees.push(
                    this.fb.group({
                        cMContractFeeID: fee.cMContractFeeID,
                        cmProductFeeID: fee.cMProductFeeID,
                        cmFeeName: fee.cMFeeName,
                        feeAmount: this.changeToCurrency(fee.feeBase),
                        feeTax: this.changeToCurrency((fee.feeBase * this.site.taxRate) / 100) + ' (' + this.site.taxRate + '%)',
                        isCollectNow: fee.isCollectNow,
                        isTaxable: fee.isTaxable,
                        isRecurring: fee.isRecurring,
                        recursEvery: fee.recursEvery,
                        recursEveryInterval: fee.recursEveryInterval,
                        isPaymentSplitable: fee.isPaymentSplitable,
                        memberSplitable: fee.memberSplitable,
                        sOrder: fee.sOrder
                    })
                );

                if (fee.memberSplitable == 2) {
                    this.feeButtonList.push({
                        cMContractFeeID: fee?.cMContractFeeID,
                        cMProductFeeID: fee.cMProductFeeID,
                        cMProductID: fee.cMProductID,
                        cMFeeName: fee.cMFeeName
                    });
                }
            });

        }
        if (isRefresh)
            this.refreshMemAmount();

    }

    toSetPrimaryMemAtFirst<T>(array): T[] {
        const index = array.findIndex(obj => obj.contactId == this.fromContactId);
        if (index !== -1) {
            const objectToMove = array.splice(index, 1)[0];
            array.unshift(objectToMove);
        }
        return array;
    }

    async getMemberFee(_memberId: number) {
        await this._contractService.getMemberFee(this.encryptedUser, this.contractId, _memberId, this.isAI).
            then(async (result: CMMemberFeeListResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.memberFeeList = response.memberFees;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-contract.getContractFee", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    getMembershipFees(mem) {
        let controls = mem.controls['memberfees']?.controls.fees['controls'];
        return controls;
    }

    async patchContact(contact) {
        await this.contactForm.patchValue({
            txtFirstName: contact.firstName,
            txtLastName: contact.lastName,
            txtEmail: contact.email,
            txtMobilePhone: contact.mobile,
            txtHomePhone: contact.homePhone,
            txtZip: contact.zip,
            txtCity: contact.city,
            txtAddress: contact.add1,
            contactId: contact.contactId,
            country: this.setCountryDD(contact.country),
            state: this.setStateDD(contact.state),
        });

        //this.countryCode = this.setCountryDD(contact.country);
        //this.stateCode = this.setStateDD(contact.state);

        await this.contactForm2.patchValue({
            dl: contact.dl,
            emgContactName: contact.emergencyCName,
            txtEmgCntPhone: contact.emergencyCPhone,
            dob: contact.dob
        });
    }

    setCountryDD(country) {
        if (this.countryDD?.length > 0) {
            let code = this.countryDD?.filter((item) => item?.country_Code?.toLowerCase() == country?.toLowerCase() || item?.country_Name?.toLowerCase() == country?.toLowerCase() || item?.country_Code2?.toLowerCase() == country?.toLowerCase())[0]?.country_Code;
            if (code) {
                return code;
            }
        }
        return "";
    }

    setStateDD(state) {
        if (this.stateList?.length > 0) {
            let code = this.stateList?.filter((item) => item?.value?.toLowerCase() == state?.toLowerCase() || item?.text?.toLowerCase() == state?.toLowerCase())[0]?.value;
            if (code) {
                return code;
            }
        }
        return "";
    }

    importPrimary() {
        if (this.contacts.length > 0) {
            this.contactForm.patchValue({
                txtHomePhone: this.contacts[0]?.homePhone,
                txtZip: this.contacts[0]?.zip,
                txtCity: this.contacts[0]?.city,
                txtAddress: this.contacts[0]?.add1,
                country: this.contacts[0]?.country,
                state: this.contacts[0]?.state
            });

            //this.countryCode = this.contacts[0]?.country;
            //this.stateCode = this.contacts[0]?.state;

            this.Log(`Imported Primary Member Info (${this.contacts[0]?.homePhone}, ${this.contacts[0]?.zip}, ${this.contacts[0]?.city}, ${this.contacts[0]?.add1}, ${this.contacts[0]?.country}, ${this.contacts[0]?.state}).`);
        }
    }

    //#endregion

    //#region site

    async getSiteData() {
        await this._clubService.Site_GetDD(this.encryptedUser, this.clpCompanyId, this.fromContactId, this.isAI).
            then(async (result: SiteDDListResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.siteList = response.sites;
                    this.clubId = response.clubId;

                    this.agentForm.patchValue({
                        agentName: response.agentName
                    });
                    this.getClubDetails();
                    if (this.classCode1) {
                        if (this.siteList.some(b => b.class1Code == this.classCode1) == true) {
                            this.site = this.siteList.filter(l => l.class1Code == this.classCode1)[0];
                            this.taxRate = this.site?.taxRate
                            this.setSite(this.site.siteID);

                            this.siteSelected = this.site.siteID

                            let filtered_s = this.siteList.filter(s => s.siteID == this.siteSelected);
                            if (filtered_s) {
                                this.siteSelectedName = filtered_s[0]?.siteName;
                            }
                        }
                    }
                    else {
                        this._notifyService.showError(`Please select a site in this contact's details.`, "", 3000);
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-contract.getSiteData", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async siteChange(siteId) {
        this.site = this.siteList?.filter((item) => item?.siteID == siteId)[0];
        this.setSite(this.site.siteID);
        this.Log(`Site changed: ${this.siteSelectedName}.`);
    }

    async setSite(siteId) {
        this.siteId = siteId;
        this.siteSelected = this.siteId;

        let filtered_s = this.siteList.filter(s => s.siteID == this.siteSelected);
        if (filtered_s) {
            this.siteSelectedName = filtered_s[0]?.siteName;
        }

        await this.getProduct();
        await this.getContractDetailsById();
        this.getContractSetting();
        await this.cmSiteEmployees_GetContractAuditor();
        await this.CMSiteEmployees_GetSigningOfficers();
    }

    async cmSiteEmployees_GetContractAuditor() {
        if (this.siteId > 0) {
            await this._clubService.CMSiteEmployees_GetContractAuditor(this.encryptedUser, this.clpCompanyId, this.siteId, this.clubId, this.isAI).
                then(async (result: CMSiteEmployeesListResponse) => {
                    if (result) {
                        let response = UtilityService.clone(result);
                        this.contractAuditorList = response.listSiteEmployees;
                        let conAuditor = this.contractAuditorList.filter(s => s.userID == this.user?.cLPUserID && (s.siteID == this.siteId || s.siteID == 0))[0]
                        if (conAuditor == null && conAuditor == undefined) {
                            conAuditor = this.contractAuditorList.filter(s => s.userID == this.user?.cLPUserID)[0]
                        }
                        this.signOfficerEmpId = conAuditor?.iD;
                        if (conAuditor != null && conAuditor != undefined && this.contractStatusId == eREF_CMContractStatus.OnHold_Review) {
                            this.isShowReview = true;
                        }
                        else {
                            this.isShowReview = false;
                        }
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("cm_contract.verifyContractAuditor", err.message, null,
                    );
                    this._utilityService.handleErrorResponse(err);
                });
        }
    }


    //#endregion

    //#region product

    async getProduct() {
        await this._productService.CMProduct_Get_All(this.encryptedUser, this.clpCompanyId, this.clubId, this.siteId, this.isAI)
            .then(async (result: ProductResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.all_productList = response?.products;

                    let filtered = this.all_productList.filter(p => p.isDefault && p.isActive);
                    if (filtered) {
                        this.product = filtered[0];

                        this.maxMembers = this.product?.intMaxMembers;
                        this.setProduct(this.product);

                        this.productSelected = this.product?.class4Code;

                        let p_filtered = this.all_productList.filter(p => p.class4Code == this.productSelected);
                        if (p_filtered) {
                            this.productSelectedName = p_filtered[0]?.productName;
                        }
                    }

                    this.productList = this.all_productList.filter(p => !p.isDeleted && p.isActive);
                }
            })
            .catch((err: HttpErrorResponse) => {
                console.log(err);
                this._utilityService.handleErrorResponse(err);
            });
    }

    setProduct(product: Product) {
        this.productSelected = product?.class4Code;

        let p_filtered = this.all_productList.filter(p => p.class4Code == this.productSelected);
        if (p_filtered) {
            this.productSelectedName = p_filtered[0]?.productName;
        }

        this.termForm.patchValue({
            term: this.product?.contractTerm
        });

        this.siteTaxForm.patchValue({
            productDesc: this.product?.productDesc
        });

        this.isSeasonal = this.product?.isSeasonal;
        this.isDays = this.product?.isDays;
        //this.isEveryday = this.product?.daysOfWeekOnPlan.split(',').length == 7;
        let daysOfWeek = this.product?.daysOfWeekOnPlan.split(',');
        this.isEveryday = !daysOfWeek?.some(item => item.toLowerCase() == 'false');


        let beginDate = this.siteTaxForm.controls["paymentBegin"].value;
        //let end = this.addMonths(this.dtBeginDate, +this.product?.contractTerm);
        let end = this.addMonths(beginDate, +this.product?.contractTerm);

        if (this.isDays) {
            this.siteTaxForm.patchValue({
                isMonday: this.product?.isMonday,
                isTuesday: this.product?.isTuesday,
                isWednesday: this.product?.isWednesday,
                isThursday: this.product?.isThursday,
                isFriday: this.product?.isFriday,
                isSaturday: this.product?.isSaturday,
                isSunday: this.product?.isSunday,
                contractEnd: end,
                contractEndLabel: this.getFormattedDate(end)
            });
        }
        else {
            this.siteTaxForm.patchValue({
                isMonday: this.product?.isMonday,
                isTuesday: this.product?.isTuesday,
                isWednesday: this.product?.isWednesday,
                isThursday: this.product?.isThursday,
                isFriday: this.product?.isFriday,
                isSaturday: this.product?.isSaturday,
                isSunday: this.product?.isSunday,
                contractEnd: end,
                contractEndLabel: this.getFormattedDate(end)
            });

            this.siteTaxForm.get('isMonday')?.disable();
            this.siteTaxForm.get('isTuesday')?.disable();
            this.siteTaxForm.get('isWednesday')?.disable();
            this.siteTaxForm.get('isThursday')?.disable();
            this.siteTaxForm.get('isFriday')?.disable();
            this.siteTaxForm.get('isSaturday')?.disable();
            this.siteTaxForm.get('isSunday')?.disable();
        }

        this.fees.controls = [];
        this.fees.removeAt(0);
        this.feeButtonList = [];

        if (this.product?.fees?.length > 0) {
            this.product?.fees.forEach((fee, index) => {
                this.fees.push(
                    this.fb.group({
                        cMContractFeeID: 0,
                        cmProductFeeID: fee.cmProductFeeID,
                        cmProductID: fee.cmProductID,
                        cmFeeName: fee.cmFeeName,
                        feeAmount: this.changeToCurrency(fee.feeAmount),
                        feeTax: this.changeToCurrency((fee.feeAmount * this.site.taxRate) / 100) + ' (' + this.site.taxRate + '%)',
                        isCollectNow: fee.isCollectNow,
                        isTaxable: fee.isTaxable,
                        isRecurring: fee.isRecurring,
                        recursEvery: fee.recursEvery,
                        recursEveryInterval: fee.recursEveryInterval,
                        isPaymentSplitable: fee.isPaymentSplitable,
                        memberSplitable: fee.memberSplitable,
                        sOrder: fee.sOrder
                    })
                );

                if (fee.memberSplitable == 2) {
                    this.feeButtonList.push({
                        cMContractFeeID: fee.cmProductFeeID,
                        cMProductFeeID: fee.cmProductFeeID,
                        cMProductID: fee.cmProductID,
                        cMFeeName: fee.cmFeeName
                    });
                }
            });

        }


        //if (this.contactDetails.length > 0) {
        //    this.totalMemberFee = this.product?.feeMembership;
        //    this.totalSignupFee = this.product?.feeSignUp;
        //}

        this.buildContactList();
    }

    contractStartChange(event: MatDatepickerInputEvent<Date>) {
        let expires = this.addDays(event.value, 7);

        this.siteTaxForm.patchValue({
            contractExpires: expires,
            contractExpiresLabel: this.getFormattedDate(expires)
        });
    }

    contractBeginsChange(event: MatDatepickerInputEvent<Date>) {
        let term = +this.termForm.controls["term"].value;
        let end = this.addMonths(event.value, term);

        this.siteTaxForm.patchValue({
            contractEnd: end,
            contractEndLabel: this.getFormattedDate(end)
        });
    }

    changeTerm() {
        let beginDate = this.siteTaxForm.controls["paymentBegin"].value;
        let end = this.addMonths(beginDate, +this.termForm.controls["term"].value);

        this.Log(`Term (Months) changed to ${this.termForm.controls["term"].value}.`);

        this.siteTaxForm.patchValue({
            contractEnd: end,
            contractEndLabel: this.getFormattedDate(end)
        });
    }

    getProductDescription() {
        return this.siteTaxForm.controls['productDesc'].value;
    }

    productChange(class4Code) {
        this.product = this.all_productList.filter(p => p.class4Code == class4Code)[0];
        this.maxMembers = this.product?.intMaxMembers
        this.setProduct(this.product);
        this.Log(`Product changed: ${this.productSelectedName}.`);
    }
    //#endregion

    //#region CMContract details

    async getContractSetting() {
        await this._contractService.cmSiteContractSetting_Get(this.encryptedUser, this.siteId, this.isAI).
            then(async (result: CMContractSettingResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.ContractSetting = response.setting;
                    this.patchConfigForm();
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-contract.getContractSetting", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    patchConfigForm() {
        this.paymentForm.patchValue({
            isEntryCC: this.ContractSetting?.isEntryCC,
            isEntryACH: this.ContractSetting?.isEntryACH,
            isEntryCheck: this.ContractSetting?.isEntryCheck,
            isEntryCash: this.ContractSetting?.isEntryCash,
            isMonthlyCC: this.ContractSetting?.isMonthlyCC,
            isMonthlyACH: this.ContractSetting?.isMonthlyACH,
            isMonthlyCheck: this.ContractSetting?.isMonthlyCheck,
        });
    }

    async getContractDetails(_contractId) {
        await this._contractService.cmContractGet(this.encryptedUser, this.clpCompanyId, 0, this.siteId, this.contractId, this.isAI).
            then(async (result: ContractResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.contracts = [];
                    this.contracts = UtilityService.clone(response.contracts);
                    if (this.contracts && this.contracts.length > 0) {
                        this.totalSignupFee = 0;
                        this.totalMemberFee = 0;
                        await this.patchContractData();
                        this.maxMembers = this.contracts[0]?.intMaxMembers
                        if (this.contracts[0].statusID == eREF_CMContractStatus.Waiting_CounterSign && this.contracts[0]?.members.filter(s => s.statusID == eREF_CMMemberStatus.Active).length == this.maxMembers) {
                            this.disableSaveContract = true
                        }
                        this.cmSiteEmployees_GetContractAuditor();
                        this.CMSiteEmployees_GetSigningOfficers();
                        if (this.contracts[0]?.members) {
                            this.contacts = [];
                            this.contracts[0]?.members.forEach((item) => {
                                this.contactId = item.contactID;
                                this.totalSignupFee += item.feeSignUp;
                                this.totalMemberFee += item.feeMembership;
                                this.getContactData(false);
                            });
                        }
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-contract.getContractDetails", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getContractDetailsById() {
        await this._contractService.cmContractGetById(this.encryptedUser, this.user?.cLPUserID, this.fromContactId, this.isAI).
            then(async (result: SimpleResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    if (response.messageInt > 0) {
                        this.contractId = response.messageInt;
                        this.cMContractFee_Get(this.contractId, () => {

                            this.getContractDetails(this.contractId);
                            this.contractEdit = false;
                        });
                        this.checkContractPayment();
                    }
                    else {
                        this.contractId = 0;
                        this.contractEdit = true;
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-contract.getContractDetails", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async getContractDeletedById() {
        await this._contractService.cmContractGetById(this.encryptedUser, this.user?.cLPUserID, this.fromContactId, this.isAI).
            then(async (result: SimpleResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    if (response.messageInt > 0) {
                        this.contractId = response.messageInt;
                        this.cMContractFee_Get(this.contractId, () => {
                            this.getContractDetails(this.contractId);
                            //this.contractEdit = false;
                        });
                        this.checkContractPayment();
                    }
                    else {
                        this.contractId = 0;
                        this.contractEdit = true;
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-contract.getContractDeletedById", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    calculateSignUpFee($event) {
        this.totalSignupFee = +$event;
        this.contacts.forEach((item) => {
            if (item.contactId == this.contactId)
                item.signupFee = +$event
        });
    }

    calculateMemberFee($event) {
        this.totalMemberFee = +$event;
        this.contacts.forEach((item) => {
            if (item.contactId == this.contactId)
                item.memberFee = +$event
        });
    }

    async saveContract(isUnEvenPayConfirm: boolean = false) {
        let invalidDates = ''
        if (this.siteTaxForm.controls['contractStart'].value == null || this.siteTaxForm.controls['contractStart'].value == undefined) {
            invalidDates = "Contract Start, "
        }

        if (this.siteTaxForm.controls['paymentBegin'].value == null || this.siteTaxForm.controls['paymentBegin'].value == undefined) {
            invalidDates = invalidDates + "Payment Begin, "
        }

        if (this.siteTaxForm.controls['contractExpires'].value == null || this.siteTaxForm.controls['contractExpires'].value == undefined) {
            invalidDates = invalidDates + "Contract Expire "
        }

        if (invalidDates?.length > 0) {
            this._notifyService.showError(invalidDates + "dates are invalid", "", 3000)
            return
        }

        this.prepareContractForm();
        let isPayByEveryMem = true;
        let feeName = "";
        let fees = '';
        let members = '';

        this.contract.fees.forEach((fee) => {
            fees += ` ${fee.cMFeeName} $${fee.feeBase},`;
        });

        fees = fees.substring(0, fees.length - 1);

        this.members.value.forEach(row => {
            let contractMemberFees = this.contract.memberfees.filter(m => m.contactID == row.contactId);
            console.log(contractMemberFees);

            //get all the fees here and add it to the member
            let firstName = row.firstName;
            let lastName = row.lastName;
            let contactId = row.contactId;
            let m_fees = '';

            contractMemberFees?.forEach(f => {
                m_fees += ` ${f.cmFeeName} ${this.changeToCurrency(f.feeBase)},`;
            });

            //row.memberFees.forEach(f => {
            //    m_fees += ` ${f.cMFeeName} ${f.feeTotal},`;
            //});
            m_fees = m_fees.substring(0, m_fees.length - 1);

            members += `${firstName} ${lastName} (${contactId}): ${m_fees}, `;
        });
        let productTotal = 0, memTotalFee = 0

        members = members.substring(0, members.length - 1);

        if (this.contractId > 0) {
            if (this.fees?.length > 0) {
                this.fees.value.forEach((fee) => {
                    if (fee?.memberSplitable == 3) {
                        this.contract.memberfees.forEach((mem) => {
                            let isExtraMem = this.contract.members.find((item) => item?.cMMemberID == mem?.memberID).isExtraMem;
                            if (fee?.cmProductFeeID == mem?.cmProductFeeID && fee?.feeAmount > 0 && !isExtraMem) {
                                if (mem?.feeBase == 0) {
                                    isPayByEveryMem = false;
                                    feeName = `${mem?.cmFeeName}, ` + feeName;
                                }
                            }
                        })
                    }

                    productTotal = productTotal + this.changeToNumber(fee.feeAmount);
                });
            }
        }
        else {
            if (this.product?.fees?.length > 0) {
                this.product?.fees.forEach((fee) => {
                    if (fee?.memberSplitable == 3) {
                        this.contract.memberfees.forEach((mem) => {
                            let isExtraMem = this.contract.members.find((item) => item?.cMMemberID == mem?.memberID).isExtraMem;
                            if (fee?.cmProductFeeID == mem?.cmProductFeeID && fee?.feeAmount > 0 && !isExtraMem) {
                                if (mem?.feeBase == 0) {
                                    isPayByEveryMem = false;
                                    feeName = `${mem?.cmFeeName}, ` + feeName;
                                }
                            }
                        })
                    }

                    productTotal = productTotal + fee?.feeAmount;
                });
            }
        }


        this.contract.memberfees.forEach((mem) => {
            memTotalFee = memTotalFee + mem.feeBase;
        });

        if (!isUnEvenPayConfirm) {
            if (productTotal != memTotalFee) {
                $('#unEqualPayConfirmModal').modal('show');
                //this._notifyService.showError(`Member fee must be equal to given product amount.`, "", 5000);
                return;
            }
        }

        if (!isPayByEveryMem) {
            this._notifyService.showError(`For ${feeName} every member must be given an amount.`, "", 5000);
            return;
        }

        if (!this.contract.cmProductID) {
            this._notifyService.showError(`Please select a product`, "", 3000);
            return;
        }

        this.showSpinner = true;
        let new_contract: boolean = false;

        if (this.contract.cmContractID <= 0) {
            new_contract = true;
        }

        await this._contractService.CMContract_Update(this.encryptedUser, this.contract, this.isAI).
            then(async (result: SimpleResponse) => {
                if (result) {
                    this.showSpinner = false;
                    let response = UtilityService.clone(result);
                    if (response.messageInt > 0) {
                        $('#unEqualPayConfirmModal').modal('hide');
                        this.contractId = response.messageInt;
                        /*For Adding members CmContract Status will be (In_Progress) and member status also*/
                        if ((this.contractStatusId == eREF_CMContractStatus.OnHold_Review || this.contractStatusId == eREF_CMContractStatus.Waiting_CounterSign || this.contractStatusId == eREF_CMContractStatus.Active) && this.contract.members.filter(x => x.cMMemberID == 0).length > 0) {

                            //only once when client is opened.
                            //this.cmContractStatus(eREF_CMContractStatus.In_Progress);
                            this.contract.members.forEach((x) => {
                                if (x.cMMemberID > 0 && x.statusID == eREF_CMMemberStatus.Active && !isNullOrUndefined(x.dtDL)) {
                                    this.cmMemberStatus(eREF_CMMemberStatus.Uploaded_Drivers_License, x.contactID, x.cMMemberID)
                                }
                                else if (x.cMMemberID > 0 && x.statusID == eREF_CMMemberStatus.Active && isNullOrUndefined(x.dtDL)) {
                                    this.cmMemberStatus(eREF_CMMemberStatus.Payment_Complete, x.contactID, x.cMMemberID)
                                }
                            })
                        }

                        /*When Member is deleted then cmContract status will be onHold or waiting*/
                        if ((this.contractStatusId < eREF_CMContractStatus.OnHold_Review) && this.contracts[0]?.members.filter(s => s.statusID >= eREF_CMMemberStatus.Payment_Complete && s.statusID <= eREF_CMMemberStatus.Uploaded_Drivers_License).length == this.contracts[0]?.members.length) {
                            if (!isNullOrUndefined(this.contracts[0]?.members)) {
                                if (this.isContractReview) {
                                    await this.cmContractStatus(eREF_CMContractStatus.OnHold_Review);
                                }
                                else {
                                    await this.cmContractStatus(eREF_CMContractStatus.Waiting_CounterSign);
                                }
                            }
                        }

                        let test_mode = '';
                        let created = 'Created';

                        if (this.contract.testMode) {
                            test_mode = `<br/><b>Test Mode</b>`;
                        }

                        if (!new_contract) {
                            created = 'Updated';
                        }

                        this.Log(`Contract Saved (${created}): <b>${this.productSelectedName}</b> at ${this.siteSelectedName}<br/>Starting ${this.contract.dtContractStart} (<b>beginning ${this.contract.dtPaymentBegins}</b>) for ${this.contract.contractTerm} months<br/><b>Fees</b>: ${fees}<br/><b>Members (${this.contract.members.length})</b>: ${members}<br/><b>Terms</b>: ${this.contract.specialTerms}.${test_mode}`);

                        if (this.contractStatusId == eREF_CMContractStatus.None)
                            await this.cmContractStatus(eREF_CMContractStatus.Configured);
                        this.getContractDetails(this.contractId);
                        this.cMContractFee_Get(this.contractId, () => {
                        });
                        this._notifyService.showSuccess("Contract saved successfully.", "Save", 3000);
                        this.contractEdit = false;
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false
                this._globalService.error("cm-contract.SaveContract", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
    }


    async deleteContract() {
        this.showSpinner = true
        await this._contractService.CMContract_Delete(this.encryptedUser, this.contractId, this.isAI).
            then(async (result: SimpleResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    if (response.messageBool) {
                        this.contractId = 0;
                        this.contracts = [];
                        this.contractEdit = false;
                        this.isValidFee = true;
                        this.isSignVisible = false
                        this.contactId = this.fromContactId
                        this.productList = []
                        this.product = <Product>{}
                        this.contacts = []
                        this.contractFees = []
                        this.fees.controls = [];
                        this.fees.removeAt(0);
                        this.feeButtonList = []
                        this.clearMembersFormArray()
                        this.getInitialContact(false);
                        this.contractStatusId = eREF_CMContractStatus.None;
                        this.agentForm.patchValue({
                            agentName: this.user.firstName + " " + this.user.lastName
                        });
                        this.termForm.patchValue({
                            term: ''
                        });
                        this.siteTaxForm.patchValue({
                            specialterms: "",
                        });
                        this.testModeForm.patchValue({
                            processorTestMode: false
                        });


                        this._notifyService.showSuccess("Contract deleted successfully.", "Delete", 3000);
                    }
                    this.showSpinner = false
                }
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false
                this._globalService.error("cm-contract.DeleteContract", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
        $('#deleteContractConfirmation').modal('hide');
    }

    clearMembersFormArray() {
        this.members.controls.forEach((group: FormGroup) => {
            const memberFees = group.get('memberFees') as FormArray;

            if (memberFees) {
                memberFees.clear();
            }
        });

        this.members.clear();
    }

    EditContract() {
        if (this.contractId > 0) {
            this.contractEdit = true;
        }
    }

    confirmDelete() {
        $('#deleteContractConfirmation').modal('show');
    }

    prepareContractForm() {
        this.contract.clpcompanyID = this.clpCompanyId;
        this.contract.clpUserID = this.user.cLPUserID;
        this.contract.agentCLPUserID = this.user.cLPUserID;
        this.contract.cmContractID = this.contractId;
        this.contract.cmProductID = this.productSelected;
        this.contract.statusID = this.contractStatusId;
        let class4Code = this.all_productList.filter(p => p.class4Code == this.productSelected)[0]?.class4Code;

        this.contract.class4Code = class4Code;

        this.contract.siteID = this.siteId;
        this.contract.clubID = this.clubId;
        this.contract.agentName = this.agentForm.controls["agentName"].value;
        this.contract.testMode = this.testModeForm.controls["processorTestMode"].value;

        this.contract.taxRate = this.site.taxRate;
        this.contract.dtContractEnd = this.datepipe.transform(this.siteTaxForm.controls["contractEnd"].value, 'MM-dd-yyyy');
        this.contract.dtContractStart = this.datepipe.transform(this.siteTaxForm.controls["contractStart"].value, 'MM-dd-yyyy');
        this.contract.dtPaymentBegins = this.datepipe.transform(this.siteTaxForm.controls["paymentBegin"].value, 'MM-dd-yyyy');
        this.contract.dtExpires = this.datepipe.transform(this.siteTaxForm.controls["contractExpires"].value, 'MM-dd-yyyy');
        this.contract.productDesc = this.siteTaxForm.controls['productDesc'].value ? this.siteTaxForm.controls['productDesc'].value : this.product?.productDesc;
        this.contract.contractTerm = this.termForm.controls["term"].value;
        this.contract.specialTerms = this.siteTaxForm.controls["specialterms"].value;

        if (this.isSeasonal)
            this.contract.monthsOnPlan = this.getSeasonalPlanText();

        if (!this.isEveryday)
            this.contract.daysOfWeekOnPlan = this.getWeeklyPlanText();

        this.contract.isSignUpCC = this.paymentForm.controls["isEntryCC"].value;
        this.contract.isSignUpACH = this.paymentForm.controls["isEntryACH"].value;
        this.contract.isSignUpCheck = this.paymentForm.controls["isEntryCheck"].value;
        this.contract.isSignUpCash = this.paymentForm.controls["isEntryCash"].value;
        this.contract.isMembershipCC = this.paymentForm.controls["isMonthlyCC"].value;
        this.contract.isMembershipACH = this.paymentForm.controls["isMonthlyACH"].value;
        this.contract.isMembershipCheck = this.paymentForm.controls["isMonthlyCheck"].value;
        this.contract.members = [];
        this.contract.fees = [];
        this.contract.memberfees = [];
        if (this.fees.value?.length > 0) {
            this.fees?.value.forEach((f, index) => {

                let tax = Math.round(this.changeToNumber(f.feeAmount) * (this.site.taxRate / 100) * 100) / 100;

                var contractFee = <CMContractFee>{
                    cMContractFeeID: f.cMContractFeeID,
                    cMContractID: 0,
                    feeBase: this.changeToNumber(f.feeAmount),
                    feeTax: f.isTaxable ? tax : 0,
                    sOrder: f?.sOrder,
                    cMProductFeeID: f?.cmProductFeeID,
                    cMFeeName: f?.cmFeeName
                }

                this.contract.fees.push(contractFee);
            });
        }
        this.members.value.forEach(row => {
            //get all the fees here and add it to the member

            var contractMember = <CMContractMember>{
                contactID: row.contactId,
                cmContractID: this.contractId,
                cMMemberID: row.cMMemberID,
                dtContractStartLabel: 0,
                dtCreated: this.datepipe.transform(new Date(), 'MM-dd-yyyy'),
                dtCreatedLabel: '',
                dteSig: this.datepipe.transform(new Date(), 'MM-dd-yyyy'),
                dteSigLabel: '',
                eSigFullImage: '',
                eSigInitialsImage: '',
                statusID: row.statusId ? row.statusId : 1,
                taxMembership: 0,
                taxSignUp: 0,
                //isPrimary: row.contactId == this.fromContactId ? true : false,
                isPrimary: row.isPrimary,
                dtDL: row.dtDL,
                isExtraMem: row?.isExtraMem ? true : (this.contractStatusId == eREF_CMContractStatus.OnHold_Review || this.contractStatusId == eREF_CMContractStatus.Waiting_CounterSign || this.contractStatusId == eREF_CMContractStatus.Active) && row.cMMemberID == 0 ? true : false
            }


            this.contract.members.push(contractMember);

            row.memberFees.forEach(f => {
                let mtax = Math.round(this.changeToNumber(f.feeBase.toString()) * (this.site.taxRate / 100) * 100) / 100;

                var memberFee = <CMMemberFee>{
                    cmMemberFeeID: f.cmMemberFeeID,
                    cmContractFeeID: f.cMContractFeeID,
                    cmProductFeeID: f.cmProductFeeID,
                    cmProductID: f.cmProductID,
                    memberID: row.cMMemberID,
                    contactID: row.contactId,
                    cmFeeName: f.cMFeeName,
                    feeTotal: this.changeToNumber(f.feeTotal),
                    feeTax: f.isTaxable ? mtax : 0,
                    sOrder: 0,
                    feeBase: this.changeToNumber(f.feeBase)
                }

                this.contract.memberfees.push(memberFee);
            });
        });
    }

    getSeasonalPlanText(): string {
        let plantype = '';

        if (this.isSeasonal) {
            plantype = this.siteTaxForm.controls["isJanuary"].value + "," + this.siteTaxForm.controls["isFebruary"].value + "," + this.siteTaxForm.controls["isMarch"].value + ',' + this.siteTaxForm.controls["isApril"].value + "," + this.siteTaxForm.controls["isMay"].value + "," + this.siteTaxForm.controls["isJune"].value + ',' + this.siteTaxForm.controls["isJuly"].value + "," + this.siteTaxForm.controls["isAugust"].value + "," + this.siteTaxForm.controls["isSeptember"].value + "," + this.siteTaxForm.controls["isOctober"].value + "," + this.siteTaxForm.controls["isNovember"].value + "," + this.siteTaxForm.controls["isDecember"].value;
        }

        return plantype;
    }

    getWeeklyPlanText(): string {
        let plantype = '';

        if (!this.isEveryday) {
            plantype = this.siteTaxForm.controls["isMonday"].value + "," + this.siteTaxForm.controls["isTuesday"].value + "," + this.siteTaxForm.controls["isWednesday"].value + ',' + this.siteTaxForm.controls["isThursday"].value + "," + this.siteTaxForm.controls["isFriday"].value + "," + this.siteTaxForm.controls["isSaturday"].value + ',' + this.siteTaxForm.controls["isSunday"].value;
        }

        return plantype;
    }

    getSearchData(txt, value: string = "") {
        let Search: Search = <Search>{};
        Search.searchText = txt;
        this.inputBoxValue = txt;
        Search.searchValue = "";
        this.getContactSearch(Search);
    }

    async goToLink(value: string, str: string = "") {
        if (value) {
            var id = this.searchList.filter(item => item.searchText == value)[0]?.searchValue;
            if (id?.includes("ct")) {
                this.contactId = +id?.split("ct")[1];
                this.isMemberExist = false
                await this.cMMember_Exists(this.contactId)

                if (this.isMemberExist) {
                    return this._notifyService.showError("This member already exists.", "Duplicate", 3000);
                }
                this.getContactById(this.contactId);
                this.autocomplete.reset();
            }
        }
        else {
            this.autocomplete.reset();
        }
    }

    patchContractData() {
        this.contractId = this.contracts[0]?.cmContractID;
        this.contractStatusId = this.contracts[0]?.statusID;
        this.agentForm.patchValue({
            agentName: this.contracts[0]?.agentName,
        });
        this.testModeForm.patchValue({
            processorTestMode: this.contracts[0]?.testMode,
        });
        this.productSelected = this.contracts[0]?.class4Code;

        let filtered = this.all_productList.filter(p => p.class4Code == this.productSelected);
        if (filtered) {
            this.productSelectedName = filtered[0]?.productName;
        }

        this.siteSelected = this.contracts[0]?.siteID;

        let filtered_s = this.siteList.filter(s => s.siteID == this.siteSelected);
        if (filtered_s) {
            this.siteSelectedName = filtered_s[0]?.siteName;
        }
        this.siteTaxForm.patchValue({
            specialterms: this.contracts[0]?.specialTerms,
            productDesc: this.contracts[0]?.productDesc,
            dtSignedMembers: this.contracts[0]?.dtSignedMembers != '0001-01-01T00:00:00' ? this.getFormattedDate(new Date(this.contracts[0]?.dtSignedMembers)) : '',
            contractStart: new Date(this.contracts[0]?.dtContractStart),
            contractStartLabel: this.getFormattedDate(new Date(this.contracts[0]?.dtContractStart)),
            paymentBegin: new Date(this.contracts[0]?.dtPaymentBegins),
            paymentBeginLabel: this.getFormattedDate(new Date(this.contracts[0]?.dtPaymentBegins)),
            contractExpires: new Date(this.contracts[0]?.dtExpires),
            contractExpiresLabel: this.getFormattedDate(new Date(this.contracts[0]?.dtExpires)),
            contractEnd: new Date(this.contracts[0]?.dtContractEnd),
            contractEndLabel: this.getFormattedDate(new Date(this.contracts[0]?.dtContractEnd)),
            contractSigned: this.getFormattedDate(new Date(this.contracts[0]?.dtExpires))
        });

        this.termForm.patchValue({
            term: this.contracts[0]?.contractTerm
        });

        this.paymentForm.patchValue({
            isEntryCC: this.contracts[0]?.isSignUpCC,
            isEntryACH: this.contracts[0]?.isSignUpACH,
            isEntryCheck: this.contracts[0]?.isSignUpCheck,
            isEntryCash: this.contracts[0]?.isSignUpCash,
            isMonthlyCC: this.contracts[0]?.isMembershipCC,
            isMonthlyACH: this.contracts[0]?.isMembershipACH,
            isMonthlyCheck: this.contracts[0]?.isMembershipCheck
        });



        if (this.contracts[0]?.daysOfWeekOnPlan) {
            let _weekOfPlan = this.contracts[0]?.daysOfWeekOnPlan.split(',');
            this.siteTaxForm.patchValue({
                isMonday: this.getBool(_weekOfPlan[0]),
                isTuesday: this.getBool(_weekOfPlan[1]),
                isWednesday: this.getBool(_weekOfPlan[2]),
                isThursday: this.getBool(_weekOfPlan[3]),
                isFriday: this.getBool(_weekOfPlan[4]),
                isSaturday: this.getBool(_weekOfPlan[5]),
                isSunday: this.getBool(_weekOfPlan[6]),
            });
        }
        if (this.contracts[0]?.monthsOnPlan) {
            let _monthPlan = this.contracts[0]?.monthsOnPlan.split(',');
            this.siteTaxForm.patchValue({
                isJanuary: this.getBool(_monthPlan[0]),
                isFebruary: this.getBool(_monthPlan[1]),
                isMarch: this.getBool(_monthPlan[2]),
                isApril: this.getBool(_monthPlan[3]),
                isMay: this.getBool(_monthPlan[4]),
                isJune: this.getBool(_monthPlan[5]),
                isJuly: this.getBool(_monthPlan[6]),
                isAugust: this.getBool(_monthPlan[7]),
                isSeptember: this.getBool(_monthPlan[8]),
                isOctober: this.getBool(_monthPlan[9]),
                isNovember: this.getBool(_monthPlan[10]),
                isDecember: this.getBool(_monthPlan[11]),
            });
        }
    }

    getFormattedDate(d: Date) {
        let curr_date = d.getDate();
        let curr_month = d.getMonth() + 1; //Months are zero based
        let curr_year = d.getFullYear();
        return curr_month + "/" + curr_date + "/" + curr_year;
    }

    confirmMemberDlt(_contactId: number, cntId: number, index) {
        $('#deleteMemberConfirmation').modal('show');
        this.memContactId = _contactId;
        this.delContactId = cntId;
        this.dltMemIndex = index
    }

    async memberDelete() {
        if (this.contracts[0]?.members.length > 0 && this.memContactId > 0) {
            /*            let memberId = this.contracts[0]?.members.filter(m => m.cMMemberID == this.memContactId)[0].cMMemberID;*/
            let del_contact = this.contacts.find(c => c.contactId == this.memContactId);

            await this._contractService.cmMemberDelete(this.encryptedUser, this.memContactId, this.isAI).
                then(async (result: SimpleResponse) => {
                    if (result) {
                        let response = UtilityService.clone(result);
                        if (response.messageInt > 0) {
                            this.Log(`${del_contact.firstName} ${del_contact.firstName} (${this.memContactId}) was removed from the contract.`);
                            this.getContractDeletedById();
                            this.contacts = this.contacts.filter(c => c.contactId != this.delContactId).slice();
                            this.contactDetails = this.contactDetails.filter(dc => dc.contactId != this.delContactId).slice();
                            this.getContactData(true);
                            $('#deleteMemberConfirmation').modal('hide');
                            this._notifyService.showSuccess("The member was removed from the contract successfully.", "Delete", 3000);
                        }
                        else {
                            this._notifyService.showError("Member could not be removed from the contract.", "Not Deleted", 3000);
                            $('#deleteMemberConfirmation').modal('hide');
                        }

                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("cm-contract.memberDelete", err.message, null,
                    );
                    this._utilityService.handleErrorResponse(err);
                });
        }
        else {
            this.contacts = this.contacts.filter(c => c.contactId != this.delContactId).slice();
            this.contactDetails = this.contactDetails.filter(dc => dc.contactId != this.delContactId).slice();
            this.contactId = this.fromContactId;
            this.getContactData(true);
            this.members.removeAt(this.dltMemIndex);
            this._notifyService.showSuccess("The member was removed from the contract successfully.", "Delete", 3000);
            $('#deleteMemberConfirmation').modal('hide');
        }
    }

    refreshMemAmount() {
        this.siteTaxForm.get('fees')['controls'].forEach((item, index) => {
            this.onChangeFees(item, index, true);
        });
    }

    getBool(value: string): boolean {
        let isTrue: boolean = false;
        if (value.indexOf('false') > -1)
            isTrue = false;
        else
            isTrue = true;
        return isTrue;
    }

    addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    addMonths(date, months) {
        var result = new Date(date);
        result.setMonth(result.getMonth() + months);
        return result;
    }

    getContactSearch(searchData: Search) {
        this._searchContactService.getContactSearchData(this.encryptedUser, this.user.cLPUserID, searchData, this.isAI)
            .then(async (result: SearchListResponse) => {
                if (result) {
                    var response = UtilityService.clone(result);
                    this.searchList = response.searchList;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("buzz-index.getLeadHistory", err.message, null, "clpUserId " + this.user.cLPUserID + "," + "searchData " + searchData);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async saveContact() {

        this.validateAllFormFields(this.contactForm);
        if (!this.contactForm.valid)
            return;

        this.prepareContact();
        this.showSpinner = true;

        await this._contractService.CMContact_Create(this.encryptedUser, this.contactData, 0, false, this.isAI).
            then(async (result: SimpleResponse) => {
                if (result) {
                    this.showSpinner = false
                    let response = UtilityService.clone(result);
                    if (response.messageInt > 0) {
                        this.contactId = response.messageInt;
                        this.getContactData(false);
                        this._notifyService.showSuccess(this.isEditMem ? "The member was updated successfully." : "The member was added successfully.", "Success", 3000);
                        this.hideModalContact();

                        let upadd = 'added';
                        if (this.isEditMem) {
                            upadd = 'updated';
                        }

                        this.Log(`Member (${this.contactData.firstName} ${this.contactData.lastName}: ${this.contactData.contactID}) ${upadd}.`);
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false
                this._globalService.error("cm-contract.saveContact", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    saveContactValid() {
        if (!this.isContactCreate ||
            !this.cform.txtFirstName.value || this.cform.txtFirstName.value == '' || (this.cform.txtFirstName.errors && (this.cform.txtFirstName.touched || this.cform.txtFirstName.dirty)) ||
            !this.cform.txtLastName.value || this.cform.txtLastName.value == '' || (this.cform.txtLastName.errors && (this.cform.txtLastName.touched || this.cform.txtLastName.dirty)) ||
            !this.cform.txtEmail.value || this.cform.txtEmail.value == '' || (this.cform.txtEmail.errors && (this.cform.txtEmail.touched || this.cform.txtEmail.dirty)) ||
            !this.cform.txtMobilePhone.value || this.cform.txtMobilePhone.value == '' || (this.cform.txtMobilePhone.errors && (this.cform.txtMobilePhone.touched || this.cform.txtMobilePhone.dirty))
        ) {
            return true;
        }
        else
            return false;
    }

    prepareContact() {
        this.contactData.contactID = this.contactId;
        this.contactData.firstName = this.contactForm.controls["txtFirstName"].value;
        this.contactData.lastName = this.contactForm.controls["txtLastName"].value;
        this.contactData.email = this.contactForm.controls["txtEmail"].value;
        this.contactData.mobile = this.contactForm.controls["txtMobilePhone"].value;
        this.contactData.homePhone = this.contactForm.controls["txtHomePhone"].value;
        this.contactData.add1 = this.contactForm.controls["txtAddress"].value;
        this.contactData.city = this.contactForm.controls["txtCity"].value;
        this.contactData.zip = this.contactForm.controls["txtZip"].value;
        this.contactData.state = this.contactForm.controls["state"].value;
        this.contactData.country = this.contactForm.controls["country"].value;
        this.contactData.dl = this.contactForm2.controls["dl"].value;
        this.contactData.dob = this.contactForm2.controls["dob"].value;
        this.contactData.emergencyCName = this.contactForm2.controls["emgContactName"].value;
        this.contactData.emergencyCPhone = this.contactForm2.controls["txtEmgCntPhone"].value;
        this.contactData.cLPUserID = this.user.cLPUserID;
        this.contactData.cLPCompanyID = this.user.cLPCompanyID;
    }

    openContact(newAdd: boolean) {
        $('#contactModal').modal('show');
        this.searchList = [];
        this.autocomplete.reset();
        setTimeout(() => {
            if (this.autocomplete) {
                this.autocomplete.focus();
            }
        }, 1000);
        if (newAdd) {
            this.contactId = 0;
            this.contactForm = this.prepareContactForm();

            this.Log(`<b>Add Member</b> opened.`);
        }
    }

    hideModalContact() {
        this.isContactCreate = false;
        this.isEditMem = false
        this.Log(`<b>Edit Member</b> dialog closed.`);
        $('#contactModal').modal('hide');
    }

    contactHide() {
        this.isContactCreate = !this.isContactCreate;

        if (this.isContactCreate) {
            this.contactForm.reset();
            this.contactForm2.reset();
        }
    }


    getFee(contactId, feeType): string {
        let amount: string = '0';
        if (this.contracts && this.contracts.length > 0) {
            this.contracts[0]?.members.forEach((item) => {
                if (item.contactID == contactId) {
                    if (feeType == 's') {
                        amount = this.changeToCurrency(item.feeSignUp);
                    }
                    else {
                        amount = this.changeToCurrency(item.feeMembership);
                    }
                }
            });
        }
        else {
            if (this.contacts.length > 0) {
                if (feeType == 's') {
                    amount = this.changeToCurrency(this.contacts.filter(c => c.contactId == contactId)[0]?.signupFee);
                }
                else {
                    amount = this.changeToCurrency(this.contacts.filter(c => c.contactId == contactId)[0]?.memberFee);
                }
            }
        }
        return amount;
    }

    feeChange(fee) {
        let total = 0
        let finalTotal = 0
        this.isValidFee = true
        let feeName = ''
        this.membersForm.get('members')['controls'].forEach(mem => {
            mem?.get('memberFees')['controls'].forEach(item => {
                finalTotal = finalTotal + this.changeToNumber(item?.value?.feeBase);
            });
        });
        let contractfeeAmount = 0;
        let productFeeAmount = 0;
        //let contractFee = this.contractFees?.filter((item) => item.cMContractFeeID == fee?.value?.cMContractFeeID)[0]

        if (this.contractId == 0) {
            this.membersForm.get('members')['controls'].forEach(mem => {
                let memberFee = mem?.get('memberFees')['controls'].filter((item) => item.value.cmProductFeeID == fee?.value?.cmProductFeeID)[0]
                total = total + this.changeToNumber(memberFee?.value?.feeBase)
            })

            let productFee = this.product?.fees?.filter((item) => item.cmProductFeeID == fee?.value?.cmProductFeeID)[0];
            this.product?.fees?.forEach((item) => {
                productFeeAmount = productFeeAmount + item.feeAmount;
            });
            if (total > productFee?.feeAmount) {
                this.isValidFinalFee = true;
                this.isValidFee = false;
                feeName = productFee?.cmFeeName;
            } else if (total < productFee?.feeAmount) {
                this.isValidFinalFee = true;
                this.isValidFee = false;
                feeName = productFee?.cmFeeName;
            }
            else if (finalTotal != productFeeAmount) {
                this.isValidFee = false;
                this.isValidFinalFee = false;
            }
        }
        else {

            this.membersForm.get('members')['controls'].forEach(mem => {
                let memberFee = mem?.get('memberFees')['controls'].filter((item) => item.value.cMContractFeeID == fee?.value?.cMContractFeeID)[0]
                total = total + this.changeToNumber(memberFee?.value?.feeBase)
            })

            let contractFee = this.contractFees?.filter((item) => item.cMContractFeeID == fee?.value?.cMContractFeeID)[0]
            this.contractFees?.forEach((item) => {
                contractfeeAmount = contractfeeAmount + item.feeBase;
            });
            if (total > contractFee?.feeBase) {
                this.isValidFinalFee = true;
                this.isValidFee = false;
                feeName = contractFee?.cMFeeName;
            } else if (total < contractFee?.feeBase) {
                this.isValidFinalFee = true;
                this.isValidFee = false;
                feeName = contractFee?.cMFeeName;
            }
            else if (finalTotal != contractfeeAmount) {
                this.isValidFee = false;
                this.isValidFinalFee = false;
            }
        }


        if (!this.isValidFee) {
            if (!this.isValidFinalFee)
                this._notifyService.showError(`Members fee is not equal to the total contract base fee.`, "", 5000);
            else
                this._notifyService.showError(`${feeName} is not equal to the total ${feeName} base fee.`, "", 5000);
        }
    }

    memberFeeChanges(fee, member) {
        try {
            let totalFee = this.changeToNumber(fee?.value?.feeBase);
            let equalFee = totalFee / this.contacts.length;
            this.membersForm.get('members')['controls'].forEach(mem => {
                mem?.get('memberFees')['controls'].forEach(c => {
                    if (c.value.cmProductFeeID == fee.cmProductFeeID && fee.memberSplitable == 1) {
                        if (mem?.value.contactId == this.fromContactId)
                            c.controls['feeBase'].setValue(this.changeToCurrency(this.changeToNumber(fee?.value?.feeBase)));
                    }
                    if (c.value.cmProductFeeID == fee.cmProductFeeID && fee.memberSplitable == 2)
                        c.controls['feeBase'].setValue(this.changeToCurrency(equalFee));
                    if (c.value.cmProductFeeID == fee.cmProductFeeID && fee.memberSplitable == 3)
                        c.controls['feeBase'].setValue(this.changeToCurrency(this.changeToNumber(fee?.value?.feeBase)));
                });
            });
        } catch (e) {
            console.log('memberFeeChanges', e);
        }
    }


    changeToCurrency(value: number): string {
        let final = '$0';

        if (value > 0) {
            final = `$${(Math.round(value * 100) / 100).toFixed(2)}`;
        }

        return final;
    }

    changeToNumber(value: string): number {
        if (value != null && value != undefined) {
            if (value?.toString().includes('$'))
                return Number(value?.replace(/\$/g, ''));
            else
                return Number(value);
        }
        else {
            return 0
        }
    }

    getStatus(statusId: number): string {
        var status = 'Pending';
        if (!isNullOrUndefined(statusId)) {
            switch (statusId) {
                case eREF_CMMemberStatus.None: {
                    status = "Pending";
                    break;
                }
                case eREF_CMMemberStatus.Ready_To_Send: {
                    status = "Ready To Send";
                    break;
                }
                case eREF_CMMemberStatus.Sent: {
                    status = "Sent";
                    break;
                }
                case eREF_CMMemberStatus.Registered: {
                    status = "Registered";
                    break;
                }
                case eREF_CMMemberStatus.Info_Update: {
                    status = "Info Updated";
                    break;
                }
                case eREF_CMMemberStatus.eSigned: {
                    status = "eSigned";
                    break;
                }
                case eREF_CMMemberStatus.Payment_Complete: {
                    status = "Payment Complete";
                    break;
                }
                case eREF_CMMemberStatus.Uploaded_Drivers_License: {
                    status = "Uploaded Driver's License";
                    break;
                }
                case eREF_CMMemberStatus.Active: {
                    status = "Active";
                    break;
                }
                default: {
                    status = "Pending";
                    break;
                }
            }
        }
        return status;
    }

    setEvenly(fee: FeeButton) {

        if (this.contacts && this.contacts.length > 1) {
            let feeValue = '';

            this.siteTaxForm.get('fees')['controls'].forEach(item => {
                if (item.value.cmProductFeeID == fee.cMProductFeeID) {

                    let simpleFee = 0, perFee = 0;
                    simpleFee = this.changeToNumber(item?.value?.feeAmount); //tax is adding at backend.
                    perFee = simpleFee / this.contacts.length;
                    this.membersForm.get('members')['controls'].forEach(mem => {
                        mem?.get('memberFees')['controls'].forEach(c => {
                            if (c.value.cmProductFeeID == fee.cMProductFeeID) {
                                feeValue = this.changeToCurrency(perFee)
                                c.controls['feeBase'].setValue(feeValue);
                            }
                        })
                    })
                }

            });

            this.feeChange(fee);

            this.Log(`${fee.cMFeeName} Split Evenly to ${feeValue}.`);
        }
    }

    changeMemberFee(fee) {
        try {
            let totalFee = this.changeToNumber(fee?.feeAmount);
            let equalFee = totalFee / this.contacts.length;
            this.membersForm.get('members')['controls'].forEach(mem => {
                mem?.get('memberFees')['controls'].forEach(c => {
                    if (c.value.cmProductFeeID == fee.cmProductFeeID && (fee.memberSplitable <= 2)) {
                        if (mem?.value.contactId == this.fromContactId) {
                            c.controls['feeBase'].setValue(this.changeToCurrency(this.changeToNumber(fee?.feeAmount)));
                        }
                        else {
                            c.controls['feeBase'].setValue(this.changeToCurrency(0));
                        }
                    }
                    //if (c.value.cmProductFeeID == fee.cmProductFeeID && fee.memberSplitable == 2)
                    //    c.controls['feeBase'].setValue(this.changeToCurrency(equalFee));
                    if (c.value.cmProductFeeID == fee.cmProductFeeID && fee.memberSplitable == 3)
                        c.controls['feeBase'].setValue(this.changeToCurrency(this.changeToNumber(fee?.feeAmount)));
                });
            });
        } catch (e) {
            console.log('changeMemberFee', e);
        }
    }

    openContactForm(_contactId: number) {
        this.isEditMem = true
        if (this.contractEdit) {
            let _contact = this.contacts.filter(c => c.contactId == _contactId)[0];
            if (_contact) {
                this.isContactCreate = true;
                this.contactId = _contactId;
                this.patchContact(_contact);
                this.openContact(false);
            }
        }
    }

    redirctf2f(_contactId: number, name: string) {
        this.Log(`<b>Face to Face</b> clicked (${name}: ${_contactId}).`);

        //only once when client is opened.
        //if (this.contractStatusId < eREF_CMContractStatus.OnHold_Review && this.members.value.filter(x => x.contactId == _contactId)[0].statusId < eREF_CMMemberStatus.Payment_Complete) {
        //    this.cmContractStatus(eREF_CMContractStatus.In_Progress);
        //}
        const url = this._router.serializeUrl(
            this._router.createUrlTree([`/cm-contract-client/${_contactId}/${this.user.cLPUserID}/${this.contractId}`])
        );
        setTimeout(() => {
            window.open(url, '_blank');
        }, 400);
        //const baseUrl = window.location.href.replace(this._router.url, '');

        //let u = `${baseUrl}${url}`;
        //window.open(u, '_blank');
    }

    async cmContractStatus(statusID: number) {
        await this._contractService.cmContractStatusUpdate(this.encryptedUser, this.contractId, this.clpCompanyId, this.user?.cLPUserID, this.user?.slurpyUserId > 0 ? this.user?.slurpyUserId : 0, 0, -1, statusID, this.isAI)
            .then(async (result: SimpleResponse) => {
                if (result) {

                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false
                this._globalService.error("cm-contract-cmContractStatus", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async cmMemberStatus(statusID: number, contactId: number, memberId: number) {
        await this._contractService.cmMemberStatusUpdate(this.encryptedUser, this.contractId, this.user?.cLPUserID, this.user?.slurpyUserId > 0 ? this.user?.slurpyUserId : 0, contactId, 0, memberId, statusID, this.isAI)
            .then(async (result: SimpleResponse) => {
                if (result) {

                }
                this.showSpinner = false
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false
                this._globalService.error("cm-contract-cmMemberStatus", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }


    async sendMemberEmail(toContactID: number, i: number) {
        await this._contractService.sendMemberEmail(this.encryptedUser, toContactID, this.contractId, this.user?.cLPCompanyID, this.user?.cLPUserID, this.user?.slurpyUserId > 0 ? this.user?.slurpyUserId : 0, this.isAI).
            then(async (result: SimpleResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    if (response.messageBool) {
                        this.emailTxtMsg = true;
                        this._notifyService.showSuccess(`Your mail was sent successfully.`, "", 3000);
                        const control = this.members.at(i).get('statusId');
                        if (control) {
                            control.setValue(2);
                        }
                    }
                    else {
                        this._notifyService.showError(`Please select an email template from contract settings & try again.`, "", 5000);
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-contract.sendMemberEmail", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async sendMemberText(toContactID: number, i: number, name: string) {
        this.txtContactId = toContactID;
        this.txtContact_i = i;
        this.textForm = this.prepareTextForm();
        this.textForm.reset();
        this.getTextMessagePH(this.user?.cLPUserID);
        this.Log(`<b>Send Contract</b> clicked (${name}: ${toContactID}).`);
    }

    private prepareTextForm(): FormGroup {
        return this.fb.group({
            txtMsg: [{ value: '' }],
            mediaURL: [{ value: '' }],
            sendEmail: [{ value: true }]
        });
    }

    async sendText() {
        let txtMsg = this.textform.txtMsg.value;
        let mediaURL = this.textform.mediaURL.value;
        let sendEmail = this.textform.sendEmail.value;

        if (txtMsg?.length > 0) {
            await this._contractService.sendMemberText(this.encryptedUser, this.txtContactId, this.contractId, this.user?.cLPCompanyID, this.user?.cLPUserID, this.user?.slurpyUserId > 0 ? this.user?.slurpyUserId : 0, txtMsg, mediaURL, sendEmail, this.isAI).
                then(async (result: SimpleResponse) => {
                    if (result) {
                        let response = UtilityService.clone(result);
                        if (response.messageBool) {
                            this.emailTxtMsg = true;
                            this._notifyService.showSuccess(`Your text message was sent successfully.`, "", 3000);

                            if (sendEmail) {
                                if (response.messageBool2) {
                                    this._notifyService.showSuccess(`Your mail was sent successfully.`, "", 3000);
                                }
                                else {
                                    this._notifyService.showError(`A problem occurred sending your mail.`, "", 3000);
                                }
                            }

                            const control = this.members.at(this.txtContact_i).get('statusId');
                            if (control) {
                                control.setValue(2);
                            }

                            $('#textConfirmation').modal('hide');

                            this.Log(`Message sent (Msg: ${txtMsg}, URL: ${mediaURL}, Email: ${sendEmail}).`);
                        }
                        else {
                            this._notifyService.showError(`A problem occurred sending your text message.`, "", 3000);
                        }
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("cm-contract.sendText", err.message, null,
                    );
                    this._utilityService.handleErrorResponse(err);
                });
        }
    }

    async cMContractFee_Get(cmContractID: number, callback) {
        await this._contractService.cMContractFee_Get(this.encryptedUser, cmContractID, this.isAI).
            then(async (result: CMContractFeeListResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.contractFees = response?.contractFees;
                    if (callback) {
                        callback();
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-contract.cMContractFee_Get", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
    }


    //#endregion

    isShowFee(fee) {

        let contractFee = this.contractFees?.filter((item) => item.cMContractFeeID == fee.value?.cMContractFeeID)[0];

        return contractFee?.memberSplitable == 2;
    }

    isFeeHidden(fee) {
        let productFee = <Fee>{}
        productFee = this.product?.fees?.filter((item) => item.cmProductFeeID == fee.value?.cmProductFeeID)[0];
        if (productFee?.feeAmount == 0) {
            return true;
        }

        return false;
    }

    isFeeBaseReadOnly(mem, fee) {
        // fee base input is read only for fees in which payment is paid by one member only & member is not primary.

        if (!this.contractEdit) {
            return true;
        }

        if (this.contractId == 0) {
            let productFee = <Fee>{}
            productFee = this.product?.fees?.filter((item) => item.cmProductFeeID == fee.value?.cmProductFeeID)[0];
            if (productFee?.memberSplitable == 1 || productFee?.feeAmount == 0) {
                return true;
            }
        }
        else {
            let contractFee = <CMContractFee>{}
            contractFee = this.contractFees?.filter((item) => item.cMContractFeeID == fee.value?.cMContractFeeID)[0];
            if (contractFee?.memberSplitable == 1 || contractFee?.feeBase == 0) {
                return true;
            }
            else {
                if ((this.contractStatusId == eREF_CMContractStatus.OnHold_Review || this.contractStatusId == eREF_CMContractStatus.Waiting_CounterSign || this.contractStatusId == eREF_CMContractStatus.Active) && (mem?.value?.statusId == null || mem?.value?.statusId == undefined || mem?.value?.statusId == 1)) {
                    return true;
                }
            }
        }

        return false;
    }

    checkMaxMem() {
        if (this.contacts?.length < this.maxMembers) {
            return true
        }
        else {
            return false
        }
    }

    async cMMember_Exists(contactId: number) {
        await this._contractService.cMMember_Exists(this.encryptedUser, contactId, this.isAI).
            then(async (result: SimpleResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.isMemberExist = response?.messageBool;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-contract.cMMember_Exists", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    onChangeFees(fee, index, refresh: boolean) {
        let value = this.changeToNumber(fee.value.feeAmount) * (this.taxRate / 100);
        const feeControl = this.fees.at(index).get('feeTax') as FormControl;
        let currency = this.changeToCurrency(value)
        feeControl.setValue(currency + ' (' + this.taxRate + '%)');
        this.changeMemberFee(fee?.value);

        if (!refresh) {
            this.Log(`${fee.value.cmFeeName} changed to ${fee.value.feeAmount}.`);
        }
    }

    async checkContractPayment() {
        await this._paymentService.CMContract_isPaymentDone(this.encryptedUser, this.contractId, this.isAI).
            then(async (result: SimpleResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.isPaymentDone = response.messageInt;
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-contract.checkContractPayment", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    copyF2FUrl(_contactId) {
        const textToCopy = `${this.bcmSiteUrl}cm-contract-client/${_contactId}/${this.user.cLPUserID}/${this.contractId}`;
        navigator.clipboard.writeText(textToCopy).then(() => {
            this.membersForm.get('members')['controls'].forEach(mem => {
                if (mem.value.contactId == _contactId) {
                    mem.controls['isF2FLinkCopied'].setValue(true);
                }
            });
            setTimeout(() => {
                this.membersForm.get('members')['controls'].forEach(mem => {
                    if (mem.value.contactId == _contactId) {
                        mem.controls['isF2FLinkCopied'].setValue(false);
                    }
                });
            }, 2000); // Reset after 2 seconds
        }).catch(err => {
            console.error('Failed to copy text: ', err);
        });
    }

    async getTextMessagePH(userId: number) {
        await this._contractService.getTextMessagePH(this.encryptedUser, userId, this.isAI).
            then(async (result: SimpleResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    this.phSendContractMessageText = response?.messageString;
                    this.phSendContractMediaURL = response?.messageString2;

                    this.textForm.patchValue({
                        txtMsg: this.phSendContractMessageText,
                        mediaURL: this.phSendContractMediaURL,
                        sendEmail: true
                    });

                    $('#textConfirmation').modal('show');
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-contract.getTextMessagePH", err.message, null,
                );
                this._utilityService.handleErrorResponse(err);
            });
    }

    async downloadContract() {
        this.isDownloaded = true;
        this.getDownloadStatus();

    }

    getDownloadStatus() {
        this.isSubscribe = setTimeout(() => {
            this.ProcessorContractPDFConvert_GET();
            this.getDownloadStatus();
        }, 3000);
    }

    getDownloadDLStatus() {
        this.isSubscribeDL = setTimeout(() => {
            this.ProcessorDLPDFConvert_GET();
            this.getDownloadDLStatus();
        }, 3000);
    }

    async ProcessorContractPDFConvert_GET() {
        await this._contractService.ProcessorContractPDFConvert_Get(this.encryptedUser, this.clpCompanyId, this.contractId, this.fromContactId, this.siteId, true, this.isAI)
            .then(async (result: ContractPdfConvertContainer) => {
                if (result) {
                    this.contractPdfConvertContainer = UtilityService.clone(result);
                    if (this.contractPdfConvertContainer?.processed) {
                        clearTimeout(this.isSubscribe);
                        this.downloadContractPdf()
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-contract.ProcessorContractPDFConvert_Get", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async downloadContractPdf() {
        this.isDownloaded = true;
        if (this.contractPdfConvertContainer?.processed) {
            await this._contractService.GetContractPDFAsync(this.encryptedUser, this.contractPdfConvertContainer?.fileName, this.contractPdfConvertContainer?.fileLength, this.isAI)
                .then((result: any) => {
                    if (result) {
                        let file = new Blob([result], { type: 'application/pdf' });
                        let fileURL = URL.createObjectURL(file);
                        let fileLink = document.createElement('a');
                        fileLink.href = fileURL;
                        let contactDetails = this.contactDetails?.filter(x => x.contactID == this.fromContactId)[0];
                        if (contactDetails != undefined && contactDetails != null && Object.keys(contactDetails)?.length > 0)
                            fileLink.download = contactDetails?.firstName + '_' + contactDetails?.lastName + "_Contracts";
                        else
                            fileLink.download = "ContractDocuments.pdf";

                        fileLink.click();
                        this.isDownloaded = false;
                        this.Log(`Contract downloaded by ${this.user.firstName} ${this.user.lastName}.`);

                    } else {
                        console.log("exportToPDF.statusCode: Error Getting Data");
                        this.isDownloaded = false;
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this.isDownloaded = false;
                    this._utilityService.handleErrorResponse(err);
                });

        }
        else {
            this.isDownloaded = false;
            this.ProcessorContractPDFConvert_GET();
        }
    }


    async downloadDL() {
        this.isDownloadedDL = true;
        this.getDownloadDLStatus();

    }

    async ProcessorDLPDFConvert_GET() {
        await this._contractService.ProcessorDLContractPDFConvert_Get(this.encryptedUser, this.clpCompanyId, this.contractId, this.fromContactId, this.siteId, this.isAI)
            .then(async (result: ContractPdfConvertContainer) => {
                if (result) {
                    this.contractPdfConvertContainerDL = UtilityService.clone(result);
                    if (this.contractPdfConvertContainerDL?.processed) {
                        clearTimeout(this.isSubscribeDL);
                        this.downloadDLPdf()
                    }
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-contract.ProcessorDLPDFConvert_GET", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async downloadDLPdf() {
        this.isDownloadedDL = true;
        if (this.contractPdfConvertContainerDL?.processed) {
            await this._contractService.GetContractPDFAsync(this.encryptedUser, this.contractPdfConvertContainerDL?.fileName, this.contractPdfConvertContainerDL?.fileLength, this.isAI)
                .then((result: any) => {
                    if (result) {
                        let file = new Blob([result], { type: 'application/pdf' });
                        let fileURL = URL.createObjectURL(file);
                        let fileLink = document.createElement('a');
                        fileLink.href = fileURL;
                        let contactDetails = this.contactDetails?.filter(x => x.contactID == this.fromContactId)[0];
                        if (contactDetails != undefined && contactDetails != null && Object.keys(this.contactDetails)?.length > 0)
                            fileLink.download = contactDetails?.firstName + '_' + contactDetails?.lastName + "_DL";
                        else
                            fileLink.download = "ContractDLDocuments.pdf";

                        fileLink.click();
                        this.isDownloadedDL = false;
                        this.Log(`Driver's License downloaded by ${this.user.firstName} ${this.user.lastName}.`);

                    } else {
                        console.log("exportToPDF.statusCode: Error Getting Data");
                        this.isDownloadedDL = false;
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this.isDownloadedDL = false;
                    this._utilityService.handleErrorResponse(err);
                });

        }
        else {
            this.isDownloadedDL = false;
            this.ProcessorDLPDFConvert_GET();
        }
    }


    async CMSiteEmployees_GetSigningOfficers() {
        if (this.siteId > 0) {
            await this._clubService.CMSiteEmployees_GetSigningOfficers(this.encryptedUser, this.clpCompanyId, this.siteId, this.clubId, this.isAI).
                then(async (result: CMSiteEmployeesListResponse) => {
                    if (result) {
                        let response = UtilityService.clone(result);
                        this.siteEmployeesList = response.listSiteEmployees;
                        let signingOff = this.siteEmployeesList.filter(s => s.userID == this.user?.cLPUserID && (s.siteID == this.siteId || s.siteID == 0))[0]
                        if (signingOff == null && signingOff == undefined) {
                            signingOff = this.siteEmployeesList.filter(s => s.userID == this.user?.cLPUserID)[0]
                        }
                        this.signOfficerEmpId = signingOff?.iD;
                        if (signingOff != null && signingOff != undefined && this.contractStatusId == eREF_CMContractStatus.Waiting_CounterSign) {
                            this.isSignVisible = true;
                            if (signingOff?.eSigFullImage && signingOff?.eSigFullImage != '') {
                                this.cMSiteEmployees_Signature_Get();
                            }
                            //else {
                            //    this.isSignCleared = true;
                            //}
                            setTimeout(() => {
                                this.resizeCanvas()
                            })
                        }
                        else {
                            if (this.contractStatusId == eREF_CMContractStatus.Waiting_CounterSign) {
                                this._notifyService.showError('A signing officer is required to countersign a contract.', '', 7000);
                            }
                            this.isSignVisible = false;
                        }
                    }
                })
                .catch((err: HttpErrorResponse) => {
                    this._globalService.error("cm_contract.CMSiteEmployees_GetSigningOfficers", err.message, null,
                    );
                    this._utilityService.handleErrorResponse(err);
                });
        }
    }

    async cMSiteEmployees_Signature_Get() {
        this.showSpinner = true;
        await this._contractService.cMSiteEmployees_Signature_Get(this.encryptedUser, this.user?.cLPCompanyID, this.signOfficerEmpId, this.user?.cLPUserID, this.isAI)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                    if (response?.messageString != '') {
                        await this.drawClearFinal()
                        this.patchSignature('data:image/png;base64,' + response?.messageString)
                    }
                }
                this.showSpinner = false;
            })
            .catch((err: HttpErrorResponse) => {
                this.showSpinner = false;
                this._globalService.error("cm-contract.Log", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }

    patchSignature(dataUrl: string) {
        setTimeout(() => {
            this.signaturePadFinal.fromDataURL(dataUrl);
        }, 2000);
    }

    async Log(message: string) {
        let log: CMContractLog = {
            sessionID: '',
            clpUserID: this.user?.cLPUserID,
            sclpUserID: this.user?.slurpyUserId > 0 ? this.user?.slurpyUserId : 0,
            contactID: 0,
            cmContractID: this.contractId,
            isFaceToFace: -1,
            notes: message
        }
        await this._contractService.CMContractLog_Create(this.encryptedUser, log, this.isAI)
            .then(async (result: SimpleResponse) => {
                if (result) {
                    let response = UtilityService.clone(result);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-contract.Log", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }

    openLog(type) {
        let url = ''
        switch (type) {
            case 'contract':
                url = `cm-contract-log/${this.contractId}?r=${this.encryptedUser}`
                setTimeout(() => {
                    window.open(url, '_blank');
                }, 400);
                break;
            case 'activity':
                url = `cm-activity-log/${this.contractId}?r=${this.encryptedUser}`
                setTimeout(() => {
                    window.open(url, '_blank');
                }, 400);
                break;
        }
    }

    async archive() {
        await this._contractService.ProcessorContractPDFConvert_Archive(this.encryptedUser, this.clpCompanyId, this.contractId, this.fromContactId, this.siteId, true, this.isAI)
            .then(async (result: ContractPdfConvertContainer) => {
                if (result) {
                    this._notifyService.showSuccess("The pdf document for this contract has been archived.", "", 5000);
                    this.Log(`PDF Document archived by ${this.user?.slurpyName}.`);
                }
            })
            .catch((err: HttpErrorResponse) => {
                this._globalService.error("cm-contract.ProcessorDLPDFConvert_GET", err.message, null);
                this._utilityService.handleErrorResponse(err);
            });
    }

    async review() {
        this.isReview = true;
        await this.cmContractStatus(eREF_CMContractStatus.Waiting_CounterSign);
        await this.refreshContract();
        this.isReview = false;

    }

    get cform() { return this.contactForm.controls; }

    get textform() { return this.textForm.controls; }

    get members(): FormArray {
        return this.membersForm.get("members") as FormArray
    }

    get memberFees(): FormArray {
        return (this.membersForm.controls["members"] as FormArray).controls['memberFees'] as FormArray
    }

    get fees(): FormArray {
        return this.siteTaxForm.get("fees") as FormArray
    }

    isShowDLDownload() {
        if (this.contracts?.length > 0 && this.contracts[0]?.members?.length > 0) {
            let isAllMem = this.contracts[0]?.members?.some(item => item?.statusID < 7);
            if (this.contractStatusId == eREF_CMContractStatus.Active && !isAllMem) {
                return false;
            }
        }
        return true;
    }
}
