<div class="main-wrapper-section">
    <div class="common-header">
        <h3>Contracts</h3>
    </div>
    <div class="card-section">
        <div class="card-panel">
            <div class="field-form-panel">
                <div class="flex-form contracts-flex-form">
                    <div class="form-group" style="max-width: 245px;">
                        <label>Site</label>
                        <kendo-multiselect #sitesRef
                                           [data]="sites"
                                           [autoClose]="false"
                                           textField="siteName"
                                           [kendoDropDownFilter]="filterSettings"
                                           [valuePrimitive]="true"
                                           [(ngModel)]="selectedSiteIDs"
                                           (valueChange)="onValueChange(sitesRef)"
                                           valueField="siteID"
                                           placeholder="--All--">
                        </kendo-multiselect>
                    </div>
                    <div class="form-group" style="max-width: 245px;">
                        <label>Manager</label>
                        <kendo-multiselect #userRef
                                           [data]="clpUsersDD"
                                           [autoClose]="false"
                                           textField="text"
                                           [kendoDropDownFilter]="filterSettings"
                                           [valuePrimitive]="true"
                                           [(ngModel)]="selectedUserIDs"
                                           (valueChange)="onValueChange(userRef)"
                                           valueField="value"
                                           placeholder="--All--">
                        </kendo-multiselect>
                    </div>
                    <div class="form-group" style="max-width: 200px;">
                        <label>Contract Status</label>
                        <kendo-combobox [data]="allContractStatus"
                                        class="form-control"
                                        [valuePrimitive]="true"
                                        [kendoDropDownFilter]="filterSettings"
                                        [filterable]="true"
                                        textField="name"
                                        [clearButton]="false"
                                        [(ngModel)]="selectedConStatus"
                                        (valueChange)="cMContractGetList()"
                                        valueField="value">
                        </kendo-combobox>
                    </div>
                    <div class="form-group" style="max-width: 190px;">
                        <label>Time Frame</label>
                        <kendo-combobox [data]="timeFrames"
                                        class="form-control"
                                        [valuePrimitive]="true"
                                        [kendoDropDownFilter]="filterSettings"
                                        [filterable]="true"
                                        textField="name"
                                        [clearButton]="false"
                                        [(ngModel)]="selectedTimeFrameFilter"
                                        (valueChange)="onTimeFrameChange()"
                                        valueField="value">
                        </kendo-combobox>
                    </div>
                    <div class="form-group" style="max-width: 280px; padding-top: 3px;">
                        <label *ngIf="selectedCtDtFilter == 1 || selectedCtDtFilter == 2"></label>
                        <kendo-combobox *ngIf="selectedCtDtFilter == 1 || selectedCtDtFilter == 2" [data]="createDateFilters"
                                        class="form-control"
                                        [valuePrimitive]="true"
                                        [kendoDropDownFilter]="filterSettings"
                                        [filterable]="true"
                                        textField="name"
                                        [clearButton]="false"
                                        [(ngModel)]="selectedCtDtFilter"
                                        (valueChange)="createDtFilterChange($event)"
                                        valueField="value">
                        </kendo-combobox>
                        <div class="custom-date" *ngIf="selectedCtDtFilter == 3" style="margin-top: -3px;">
                            <label>Custom Range</label>
                            <div class="display-row">
                                <mat-form-field appearance="outline">
                                    <mat-date-range-input [rangePicker]="dateRangePicker" [formGroup]="currentRange">
                                        <input matStartDate placeholder="Start date" formControlName="start" #dateRangeStart>
                                        <input matEndDate placeholder="End date" formControlName="end" #dateRangeEnd (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)">
                                    </mat-date-range-input>
                                    <mat-datepicker-toggle class="custom-daterange-toggle" matPrefix [for]="dateRangePicker"></mat-datepicker-toggle>
                                    <mat-date-range-picker #dateRangePicker></mat-date-range-picker>
                                    <span *ngIf="currentRange.controls.start.hasError('matStartDateInvalid')"><mat-error>Invalid start date</mat-error></span>

                                    <span *ngIf="currentRange.controls.end.hasError('matEndDateInvalid')"><mat-error>Invalid end date</mat-error></span>
                                </mat-form-field>
                                <i class="fa fa-undo" *ngIf="customFilter" (click)="revertCustomFinalizeddtFilter()" style="font-size:20px; margin-left:10px;"></i>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label></label>
                        <div class="button-group">
                            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
                        </div>
                    </div>
                </div>
            </div>
            <div class="table-panel">
                <div class="">
                    <div class="text-right">
                        <div class="button-group" style="border:none;">
                        </div>
                    </div>
                </div>
                <div class="table-body">
                    <table mat-table [dataSource]="dataSource" class="contracts-table" matSort>
                        <ng-container matColumnDef="Members">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Members</th>
                            <td mat-cell *matCellDef="let element"> <div class="members-name" [innerHTML]="formatMems(element.Members) | safeHtml"></div></td>
                        </ng-container>
                        <ng-container matColumnDef="productDesc">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Product</th>
                            <td mat-cell *matCellDef="let element"><div class="members-product">{{element.productDesc}}</div></td>
                        </ng-container>
                        <ng-container matColumnDef="siteName">
                            <th mat-header-cell *matHeaderCellDef>Site</th>
                            <td mat-cell *matCellDef="let element"><div class="members-site">{{element.siteName}}</div></td>
                        </ng-container>
                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                            <td mat-cell *matCellDef="let element"><div class="members-status" [innerHTML]="element.status | safeHtml"></div></td>
                        </ng-container>
                        <ng-container matColumnDef="dtCreated">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Created</th>
                            <td mat-cell *matCellDef="let element"><div class="members-date">{{formatDate(element.dtCreated)}}</div></td>
                        </ng-container>
                        <ng-container matColumnDef="dtContractStart">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Start</th>
                            <td mat-cell *matCellDef="let element"><div class="members-date">{{formatDate(element.dtContractStart)}}</div></td>
                        </ng-container>
                        <ng-container matColumnDef="dtContractEnd">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>End</th>
                            <td mat-cell *matCellDef="let element"><div class="members-date">{{formatDate(element.dtContractEnd)}}</div></td>
                        </ng-container>
                        <ng-container matColumnDef="dtPaymentBegins">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Payment Begins</th>
                            <td mat-cell *matCellDef="let element"><div class="members-date">{{formatDate(element.dtPaymentBegins)}}</div></td>
                        </ng-container>
                        <ng-container matColumnDef="dteSig">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Signed</th>
                            <td mat-cell *matCellDef="let element"><div class="members-date">{{formatDate(getDate(element.dteSig))}}</div></td>
                        </ng-container>
                        <ng-container matColumnDef="fees">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Fees</th>
                            <td mat-cell *matCellDef="let element"><div class="members-fees" [innerHTML]="element.fees | safeHtml"></div></td>
                        </ng-container>
                        <!--<ng-container matColumnDef="dtPaymentCollect">
                            <th mat-header-cell *matHeaderCellDef>Payment COllect</th>
                            <td mat-cell *matCellDef="let element"><div class="members-site">{{ formatDate(element.dtPaymentCollect)}}</div></td>
                        </ng-container>-->
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectRow(row)">
                        </tr>
                    </table>
                    <mat-paginator *ngIf="contractList?.length > 0"
                                   [pageSize]="pageSize"
                                   (page)="onPageChange($event)" [length]="contractList?.length" [pageSizeOptions]="[5, 10, 15, 50]"></mat-paginator>
                </div>
            </div>
        </div>
    </div>
</div>

