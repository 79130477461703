import { deviceInfo } from "./device-info.model";
import { GenericResponse, SimpleResponse } from "./generic-response.model";
import { PasswordPolicy } from "./password-policy.model";
import { RoleFeaturePermissions } from "./role-container.model";

export interface UserResponse extends SimpleResponse {
    user: CLPUser;
    userId: number;
    encryptedToken: string;
    exists: boolean;
    msg: string;
    isvalid: number;
    passwordPolicy: PasswordPolicy;
    roleFeaturePermissions: RoleFeaturePermissions;
    cFirstName: string;
    cLastName: string;
}


export interface CLPUser {
    TxtMsgTollFree: string;
    resetPassword: ResetPassword;
    encryptedPassword: string;
    cLPUserID: number;
    cLPCompanyID: number;
    cLPSSID: number;
    teamCode: number;
    userName: string;
    password: string;
    question: number;
    answer: string;
    officeCode: number;
    lastName: string;
    firstName: string;
    fullName: string;
    middleInitial: string;
    employeeID: string;
    permissions: number;
    changePW: number;
    timeZone: number;
    userRole: number;
    title: string;
    add1: string;
    add2: string;
    add3: string;
    city: string;
    state: string;
    zip: string;
    country: string;
    enableSkype: boolean;
    phone: string;
    mobile: string;
    altPhone: string;
    fax: string;
    enableFax2Mail: boolean;
    fax2EmailFrom: string;
    email: string;
    otherEmail: string;
    useBothEmail: boolean;
    emailFormat: number;
    defaultSignature: string;
    hTMLSignature: string;
    fromDisplayName: string;
    uAlias: string;
    emailTemplateID: number;
    helpMode: boolean;
    isNewUser: boolean;
    dShareContact: boolean;
    dOutlookSync: boolean;
    isAllowDownload: boolean;
    facebookURL: string;
    twitterURL: string;
    linkedInURL: string;
    enableCLPSMTP: boolean;
    enableiPhone: boolean;
    dtCreated: string;
    txtMsgLongCode: string;
    dtPassword: string | null;
    failedAttempts: number;
    pwdChangeInDay: number;
    isLocked: number;
    dtlocked: string | null;
    isPasswordAdmin: boolean;
    passwordHash: string;
    slurpyUserId: number;
    slurpyName: string;
    companyName: string;
    status: number;
    isShowCP: boolean;
    timeZoneWinId: number;
    dateFormatId: number;
    standardName: string;
    dateFormat: string;
    theme: number;
    tzoffset: number;
    deviceInfo: DeviceInfo;
}
export interface DeviceInfo {
    ip: string;
    reverse: string;
    countrycode: string;
    countrycode3: string;
    countryname: string;
    city: string;
    region: string;
    regionName: string;
    lat: number;
    lon: number;
    callingCode: string;
    isp: string;
    asName: string;
    org: string;
    hosting: boolean;
    mobile: boolean;
    message: string;
    browser: string;
    browser_version: string;
    device: string;
    isDesktop: boolean;
    isMobile: boolean;
    isTablet: boolean;
    os: string;
    os_version: string;
    userAgent: string;
    dtClientMachine: string;
    userName: string;
    error: string;
    dtCreated: string;
    captchaResponse: string;
}

export interface ResetPassword {
    currentPassword: string;
    //currentPwd: string;
    newPassword: string;
    //newPwd: string;
    isForget: number;
}

export interface TeamCodeResponseIEnumerable extends GenericResponse {
    teamCodes: TeamCodes[];
}

export interface TeamCodes {
    teamCode: number;
    cLPCompanyID: number;
    display: string;
    sOrder: number;
    companyName: string;
}

export interface TeamResponse extends SimpleResponse {
    team: TeamCodes;
}

export interface BCMUserResponse extends SimpleResponse {
    user: BCMCLPUser;
    userId: number;
    encryptedToken: string;
    exists: boolean;
    msg: string;
    isvalid: boolean;
    passwordPolicy: PasswordPolicy;
}

export interface BCMCLPUser {
    clientServicesPermissions: CLPUserFeaturePermissions;
    cLPUserID: number;
    cLPCompanyID: number;
    cLPSSID: number;
    teamCode: number;
    userName: string;
    password: string;
    question: number;
    answer: string;
    officeCode: number;
    lastName: string;
    firstName: string;
    middleInitial: string;
    employeeID: string;
    permissions: number;
    changePW: number;
    timeZone: number;
    userRole: number;
    title: string;
    add1: string;
    add2: string;
    add3: string;
    city: string;
    state: string;
    zip: string;
    country: string;
    enableSkype: boolean;
    phone: string;
    mobile: string;
    altPhone: string;
    fax: string;
    enableFax2Mail: boolean;
    fax2EmailFrom: string;
    email: string;
    otherEmail: string;
    useBothEmail: boolean;
    emailFormat: number;
    defaultSignature: string;
    hTMLSignature: string;
    fromDisplayName: string;
    uAlias: string;
    emailTemplateID: number;
    helpMode: boolean;
    isNewUser: boolean;
    dShareContact: boolean;
    dOutlookSync: boolean;
    isAllowDownload: boolean;
    facebookURL: string;
    twitterURL: string;
    linkedInURL: string;
    enableCLPSMTP: boolean;
    enableiPhone: boolean;
    dtCreated: string;
    txtMsgLongCode: string;
    dtPassword: string | null;
    failedAttempts: number;
    pwdChangeInDay: number;
    isLocked: number;
    dtlocked: string | null;
    isPasswordAdmin: boolean;
    passwordHash: string;
    countryCode: string;
    timeZoneWinId: number;
    dateFormatId: number;
    theme: number;
    txtMsgTollFree: string;
    companyName: string;
    status: number;
    dateFormat: string;
    standardName: string;
    slurpyUserId: number;
    slurpyName: string;
    isShowCP: boolean;
    fullName: string;
}

export interface CLPUserFeaturePermissions {
    view: boolean;
    create: boolean;
    edit: boolean;
    delete: boolean;
    canAccess: boolean;
}

export interface CMUserPref {
    clpUserID: number;
    phSendContractMessageText: string;
    phSendContractMediaURL: string;
}

export interface CMContact {
    contactID: number;
    contractId: number;
    email: string;
    mobile: string;
}

export interface ContactCustomField {
    customTitle: string;
    customValue: string;
}

export interface ContactCustomFieldResponse extends SimpleResponse {
    contactCustomFields: ContactCustomField[];
}
